import React from 'react';
import { colors } from '../../GlobalColors';
import styled from 'styled-components';

const Container = styled.button`
  background-color: transparent;
  color: ${colors.teal};
  border: 0.15rem solid ${colors.teal};
  box-sizing: border-box;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem;
  width: ${(props) => (props.small ? '10rem' : '15rem')};
  height: ${(props) => (props.small ? '10rem' : '15rem')};
  font-size: ${(props) => (props.small ? '0.8rem' : '1.2rem')};
  img {
    height: ${(props) => (props.small ? '5rem' : '7.5rem')};
  }
  :hover {
    border-color: ${colors.red};
    img {
      filter: invert(43%) sepia(91%) saturate(7495%) hue-rotate(341deg)
        brightness(94%) contrast(94%);
    }
    p {
      color: ${colors.red};
    }
  }
  @media (max-width: 400px) {
    width: 10rem;
    height: 10rem;
    font-size: 0.8rem;
    img {
      width: 5rem;
    }
  }
`;

const LinkButton = ({ onClick, imgsrc, buttontext, small }) => {
  return (
    <Container onClick={onClick} small={small}>
      <img src={imgsrc} alt='' />
      <p>{buttontext}</p>
    </Container>
  );
};

export default LinkButton;
