import axios from 'axios';
import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import { getFileTypeFromUrl } from './getMode';
import ProfileDefault from '../Assets/Images/ProfileDefault.svg';

export const getToken = async () => {
  const token = await Auth.currentSession();
  console.log(token);
  return await token.getIdToken().getJwtToken();
};

export const getAccessToken = async () => {
  const session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// 'http://localhost:3000/dev';

// dataSuppliers
export const getDataSuppliers = async () => {
  return axios
    .get('/dataSuppliers')
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

// fileTypes
export const getFileTypes = async () => {
  return axios
    .get('/fileTypes')
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

// procedures
export const getProcedures = () => {
  return axios
    .get('/procedures')
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

// surgicalEventFiles
export const updateFile = async (fileId, progress, status) => {
  return axios
    .put(
      `/surgicalEventFiles/${fileId}`,
      {
        uploadProgress: progress,
        uploadStatus: status,
      },
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    )
    .catch((err) => console.log('ERROR: ', err));
};

export const getSurgicalEventFiles = async (
  search,
  status,
  eventId,
  dataSupplierId,
  isdeleted
) => {
  const mode = getFileTypeFromUrl();
  const searchParam = search ? `&search=${search}` : '';
  const statusParam = `&uploadStatus=${status ?? 'complete'}`;
  const eventParam = eventId ? `&surgicalEventId=${eventId}` : '';
  const restoreParam = isdeleted === "deleted" ? `&toRestoreBy=notNull` : `&toRestoreBy=null`;
  const dataSupplierParam = dataSupplierId
    ? `&dataSupplierId=${dataSupplierId}`
    : '';
  return axios
    .get(
      `/surgicalEventFiles?type=${mode}${searchParam}${statusParam}${eventParam}${dataSupplierParam}${restoreParam}`,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

export const getEventFileById = async (id) => {
  return axios
    .get(`/surgicalEventFiles/${id}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

export const get3dModelFileById = async (id) => {
  return axios
    .get(`/getSegmentorOutputFile/${id}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

export const getViewSlicerFileById = async (id) => {
  return axios
    .get(`/getTotalSegmentorOutputFile/${id}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

export const deleteEventFile = async (id) => {
  return axios
    .delete(`/surgicalEventFiles/${id}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      notification.error({
        message: 'Error',
        description: 'File not found',
        placement: 'topRight',
      });
      return;
    });
};

export const restoreSurgicalEventFile = async (id) => {
  return axios
    .get(`/restoreSurgicalEventFile/${id}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      notification.error({
        message: 'Error',
        description: 'File not found',
        placement: 'topRight',
      });
      return;
    });
};

// surgicalEvents
export const getSurgicalEvents = async () => {
  return axios
    .get('/surgicalEvents?dataSupplierId=mine', {
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

export const createSurgicalEvent = async (display, patientRef, procId) => {
  return axios
    .post(
      '/surgicalEvents',
      {
        display: display,
        patientReference: patientRef,
        procedureId: procId,
      },
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    )
    .catch((err) => console.log('ERROR: ', err));
};

// upload
export const getPresignedPostData = async (image, eventId, fileTypeId) => {
  return axios
    .post(
      '/upload',
      {
        name: image.name,
        surgicalEventId: eventId,
        fileTypeId: fileTypeId,
      },
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    )
    .catch((err) => console.log('ERROR: ', err));
};

// users
export const getCurrentUser = async () => {
  return axios
    .get('/users/current', {
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

export const getUserProfilePicture = async () => {
  return axios
    .get('/users/current', {
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => {
      if (res.data.avatarFilename) {
        return res.data.profilePictureUrl;
      } else {
        return ProfileDefault;
      }
    })
    .catch((err) => console.log('ERROR: ', err));
};

export const updateUserInfo = async (user) => {
  const { id, title, firstName, lastName } = user;
  return axios
    .put(
      `/users/${id}`,
      {
        title: title,
        firstName: firstName,
        lastName: lastName,
      },
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    )
    .catch((err) => console.log('ERROR: ', err));
};

export const updateUserProfilePicture = async (id, fileName) => {
  return axios
    .put(
      `/users/${id}`,
      {
        avatarFilename: fileName,
      },
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    )
    .catch((err) => console.log('ERROR: ', err));
};

export const getUserById = async (id) => {
  return axios
    .get(`/users/${id}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => res.data)
    .catch((err) => console.log("ERROR: ", err));
};

//sagemaker
export const getSagemakerJobs = async () => {
  return axios
    .get(`/getSageMakerJobs`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .catch((err) => console.log('ERROR: ', err));
};

export const getGlobalJobsJson = async () => {
  return axios
    .get(`/getGlobalJobsJson`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .catch((err) => console.log('ERROR: ', err));
};

export const getJobMainfest = async (bucketUrl) => {
  return axios
    .get(`/getJobMainfest?bucketUrl=${bucketUrl}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .catch((err) => console.log('ERROR:1 ', err));
};

export const getSagemakerJobsByName = async (jobName) => {
  if (!jobName) {
    throw new Error('No job name found');
  }
  return axios
    .get(`/getSageMakerJob/${jobName}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .catch((err) => console.log('ERROR: ', err));
};

export const startSegmentationByFileId = async (fileId) => {
  if (!fileId) {
    throw new Error('No file id found');
  }
  return axios
    .get(`/moveFileSegmentor/${fileId}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .catch((err) => console.log('ERROR: ', err));
};

export const getSignedUrl = async (bucket, key) => {
  return axios
    .get(`/getSignedUrl?bucket=${bucket}&key=${key}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .catch((err) => console.log('ERROR:1 ', err));
};

export const getS3UploadUrl = async ({ bucket, key, contentType }) => {
  console.log('key >> ', key);
  return axios
    .post(
      '/putObject',
      {
        contentType: contentType,
        bucket: bucket,
        key: key,
      },
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    )
    .catch((err) => console.log('ERROR: ', err));
};

export const generateOtpEmailFor3dModal = async (fileId, email, phone) => {
  return axios
    .post(`/generateOtpEmailFor3dModal`, {
      headers: {
        Authorization: await getToken(),
        fileId:fileId,
        email:email,
        phone:phone,
      },
    })
    .catch((err) => console.log('ERROR: ', err));
};

export const generateSecretEmailFor3dModal = async (fileId, email, secret) => {
  return axios
    .post(`/generateSecretEmailFor3dModal/${fileId}`,
    {
      email:email,
      secret:secret
    },
     {
      headers: {
        Authorization: await getToken(),
      },
    })
    .catch((err) => console.log('ERROR: ', err));
};

export const verifyOtpFor3dModal = async (phone, otp) => {
  if (!otp) {
    throw new Error('OTP is required');
  }
  return axios
    .post(`/verifyOtpFor3dModal`, {
      headers: {
        phone:phone,
        otp:otp
      },
    })
    .catch((err) => {
      console.log('ERROR: ', err)
    });
};

export const verifySecretFor3dModal = async (fileId) => {
  if (!fileId) {
    throw new Error('No file id found');
  }
  return axios
    .get(`/verifySecretFor3dModal/${fileId}`)
    .catch((err) => console.log('ERROR: ', err));
};

export const get3dModelFileByIdPatient = async (id) => {
  if (!id) {
    throw new Error('No file id found');
  }
  return axios
    .get(`/getSegmentorOutputFilePatient/${id}`)
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};