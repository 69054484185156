import React, { useEffect, useState, useContext, useCallback } from 'react';
import {
  Button,
  Popconfirm,
  Tooltip,
  Progress,
  notification,
  Input,
} from 'antd';
import {
  CloseSquareOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Dashboard from '../../Components/Dashboard';
import Spinner from '../../Components/Spinner';
import LayoutColumn from '../../Components/LayoutColumn';
import ListContainer from '../../Components/FileList/ListContainer';
import ListItemany from '../../Components/FileList/itemany';
import QuickLinks from '../../Components/QuickLinks';
import VideoPlayer from '../../Components/VideoPlayer';
import { colors } from '../../GlobalColors';
import {
  getDataSuppliers,
  getSurgicalEventFiles,
  getEventFileById,
  get3dModelFileById,
  getSurgicalEvents,
  deleteEventFile,
  restoreSurgicalEventFile,
} from '../../Utils/axios';
import { getFileTypeFromUrl } from '../../Utils/getMode';
import ToolBar from '../../Components//ToolBar';
import { Context } from '../../Utils/UserContext';
import RestoreImaging from '../../Assets/Icons/Undo.svg';

const ViewLayout = ({ pageTitle, listIcon, quickLinksArray }) => {
  const { user } = useContext(Context);
  const [files, setFiles] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [signedUrl, setSignedUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [pageItems, setPageItems] = useState(null);
  const [filter, setFilter] = useState(null);
  const [dataSuppliers, setDataSuppliers] = useState();
  const [surgicalEvents, setSurgicalEvents] = useState();
  const [search, setSearch] = useState('');
  const [showFailed, setShowFailed] = useState(false);
  const [requestedFiles, setRequestedFiles] = useState(false);
  const now = moment();

  const mode = getFileTypeFromUrl();

  const itemsPerPage = 6;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginationData = {
    firstIndex: indexOfFirstItem,
    lastIndex: indexOfLastItem,
    numberItems: files?.length ?? 0,
    itemsPerPage: itemsPerPage,
    currentPage: currentPage,
    setCurrentPage: setCurrentPage,
  };

  useEffect(() => {
    getSurgicalEvents().then((res) => {
      setSurgicalEvents(res?.data ?? []);
    });

    if (user?.roles?.isSuperUser) {
      getDataSuppliers().then((res) => {
        setDataSuppliers(res?.data ?? []);
      });
    } else {
      setDataSuppliers(user?.dataSupplier);
    }
  }, [user]);

  const getFiles = useCallback(() => {
    let eventId = null;
    let dataSupplierId = null;
    let status = showFailed ? 'incomplete' : 'complete';

    if (filter) {
      eventId =
        typeof filter === 'number' && !user?.roles?.isSuperUser ? filter : null;
      dataSupplierId =
        (typeof filter === 'number' || filter === 'mine') &&
        user?.roles?.isSuperUser
          ? filter
          : null;
    }

    getSurgicalEventFiles(
      search,
      status,
      eventId,
      dataSupplierId,
      'deleted'
    ).then((res) => {
      if (res) {
        let myFiles;
        if (user?.roles?.isDoctor) {
          if (requestedFiles) {
            myFiles = res?.data.filter(
              (f) => f.doctorRefId === user?.id.toString()
            );
            console.log(myFiles);
          } else {
            myFiles = res?.data.filter((f) => f.uploadedById === user.id);
          }
        } else if (user?.roles?.isDiagnostic || user?.roles?.isSuperPatient) {
          myFiles = res?.data.filter((f) => f.uploadedById === user.id);
        } else {
          if (filter && filter === 'my uploads') {
            myFiles = res?.data.filter((f) => f.uploadedById === user.id);
          } else {
            myFiles = res?.data;
          }
        }

        setFiles(myFiles);
      } else {
        setFiles([]);
        setPageItems([]);
      }
    });
  }, [filter, search, user, showFailed, requestedFiles]);

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  useEffect(() => {
    if (files && files.length) {
      const lastPage = Math.ceil(files.length / itemsPerPage);
      const items =
        currentPage === lastPage
          ? files.slice(indexOfFirstItem)
          : files.slice(indexOfFirstItem, indexOfLastItem);
      setPageItems(items);
    } else {
      setPageItems([]);
    }
  }, [files, currentPage, indexOfFirstItem, indexOfLastItem]);

  const handleClick = async (file) => {
    const signed = await getEventFileById(file.id).then((res) => {
      return res?.data?.signedUrl;
    });
    if (mode === 'video') {
      setSignedUrl(signed);
      setShowModal(true);
    } else {
      const fileName = await file.fileUrl.split('/').pop();
      const encodedUrl = encodeURIComponent(signed);
      window.location = `/medical-imaging-viewer?name=${fileName}&url=${encodedUrl}`;
    }
  };

  const view3dModel = async (file) => {
    const signed = await get3dModelFileById(file.id).then((res) => {
      return res?.data?.signedUrl;
    });
    if (mode === 'video') {
      setSignedUrl(signed);
      setShowModal(true);
    } else {
      console.log('opeing 3d viewerr');
      window.location = `/3d-viewer/website/#model=${signed}`;
    }
  };

  const getFileHeader = () => {
    let header;
    if ((!filter && user?.roles?.isSuperUser) || (filter && filter === 'all')) {
      header = `All Uploads`;
    } else if (
      (!filter && !user?.roles?.isSuperUser) ||
      (filter && filter === 'mine')
    ) {
      header = `All ${user?.dataSupplier?.name} Uploads`;
    } else if (filter && filter === 'my uploads') {
      header = 'My Uploads';
    } else if (
      filter &&
      typeof filter === 'number' &&
      !user?.roles?.isSuperUser
    ) {
      let event = surgicalEvents.find((e) => e.id === filter);
      header = `Surgical Event: ${event.display}`;
    } else if (
      filter &&
      typeof filter === 'number' &&
      user?.roles?.isSuperUser
    ) {
      let dataSupplier = dataSuppliers.find((s) => s.id === filter);
      header = `Data Supplier: ${dataSupplier.name}`;
    }
    return `${header}${showFailed ? ' (Incomplete)' : ''}`;
  };

  const deleteFile = (file) => {
    deleteEventFile(file.id)
      .then(() => {
        getFiles();
      })
      .catch((err) => console.log('ERROR: ', err));
  };

  const restoreFile = (file) => {
    restoreSurgicalEventFile(file.id)
      .then(() => {
        getFiles();
      })
      .catch((err) => console.log('ERROR: ', err));
  };

  const buttonProps = {
    type: 'default',
    style: { color: colors.teal, borderColor: colors.teal },
  };

  function timeLeft(started) {
    const restoreAt = moment(started);
    const restoreAtPlus30Days = restoreAt.clone().add(30, 'd');
    const remain = restoreAtPlus30Days.diff(moment(now));
    const duration = moment.duration(remain);
    const days = duration.days();
    return days;
  }

  return (
    <Dashboard
      pageTitle={pageTitle}
      prev={mode === 'video' ? '/video' : '/imaging'}
      tools={
        <ToolBar
          isSuperUser={user?.roles?.isSuperUser}
          userSupplier={user?.dataSupplier}
          canFilterByUploader={
            user?.roles?.isViewer || user?.roles?.isSupplierAdmin
          }
          dataSuppliers={dataSuppliers}
          surgicalEvents={surgicalEvents}
          showCheck={false}
          setShowFailed={setShowFailed}
          setFilter={(e) => {
            setFilter(e);
            setCurrentPage(1);
          }}
          onClearFilter={() => {
            setFilter(null);
            setCurrentPage(1);
          }}
          search={search}
          setSearch={setSearch}
          setPageOne={() => setCurrentPage(1)}
          showFilter={
            user?.roles?.isDoctor ||
            user?.roles?.isDiagnostic ||
            user?.roles?.isSuperPatient
          }
        />
      }
    >
      {!files ? (
        <Spinner />
      ) : (
        <LayoutColumn
          left={
            <>
              <ListContainer
                isDoctor={user?.roles?.isDoctor}
                title={getFileHeader()}
                paginationData={paginationData}
                requestedFiles={requestedFiles}
                setRequestedFiles={setRequestedFiles}
              >
                {pageItems && pageItems.length ? (
                  pageItems.map((file) => (
                    <div key={file.id}>
                      <ListItemany
                        onClickFile={
                          mode === 'video' ? () => handleClick(file) : null
                        }
                        mode={mode}
                        icon={
                          file.uploadStatus === 'complete' ? (
                            listIcon
                          ) : (
                            <CloseSquareOutlined
                              style={{
                                marginRight: '0.5rem',
                              }}
                            />
                          )
                        }
                        name={file.fileUrl.split('/').pop()}
                        centerContent={
                          <>
                            {mode === 'ct' && (
                              <Button
                                type='primary'
                                disabled={false}
                                style={{
                                  height: 50,
                                  fontSize: 10,
                                  borderRadius: 10,
                                }}
                                onClick={() => handleClick(file)}
                              >
                                View
                                <br /> Imaging
                              </Button>
                            )}
                          </>
                        }
                        leftContent={
                          <>
                            {mode === 'ct' &&
                              (!file.segmentStatus ||
                                file.segmentStatus === 'pending' ||
                                file.segmentStatus === 'in progress' ||
                                file.segmentStatus === 'view slicer') && (
                                <Button
                                  type='primary'
                                  disabled={true}
                                  style={{
                                    height: 50,
                                    fontSize: 10,
                                    borderRadius: 10,
                                  }}
                                >
                                  View 3D
                                  <br />
                                  Model
                                </Button>
                              )}
                            {mode === 'ct' &&
                              file.segmentStatus === 'complete' && (
                                <Button
                                  type='primary'
                                  disabled={false}
                                  style={{
                                    height: 50,
                                    fontSize: 10,
                                    borderRadius: 10,
                                  }}
                                  onClick={() => view3dModel(file)}
                                >
                                  View 3D
                                  <br />
                                  Model
                                </Button>
                              )}
                          </>
                        }
                        rightContent={
                          <div style={{ display: 'flex', gap: '4px' }}>
                            <Popconfirm
                              title='Permanently Delete File? We can not restore it after deleting from here.'
                              icon={
                                <QuestionCircleOutlined
                                  style={{ color: 'red' }}
                                />
                              }
                              okText='Confirm'
                              cancelText='Exit'
                              okButtonProps={buttonProps}
                              cancelButtonProps={buttonProps}
                              onConfirm={() => deleteFile(file)}
                            >
                              <Tooltip title='Permanently Delete'>
                                <Button
                                  type='primary'
                                  shape='circle'
                                  icon={<DeleteOutlined />}
                                  style={{
                                    background: 'red',
                                    position: 'absolute',
                                    top: 10,
                                    borderColor: colors.red,
                                  }}
                                />
                              </Tooltip>
                            </Popconfirm>
                            <Tooltip title='Restore'>
                              <Button
                                type='primary'
                                shape='circle'
                                key={file.id}
                                icon={
                                  <img
                                    src={RestoreImaging}
                                    alt=''
                                    style={{
                                      height: '16px',
                                      paddingLeft: '2px',
                                      paddingBottom: '2px',
                                    }}
                                  />
                                }
                                onClick={() => restoreFile(file)}
                                style={{
                                  position: 'relative',
                                  top: 10,
                                  left: 40,
                                  borderColor: colors.blue,
                                  backgroundColor: 'white',
                                }}
                              />
                            </Tooltip>
                          </div>
                        }
                        dateText={
                          file.uploadStatus === 'complete' ? (
                            <Tooltip title='Restore before'>
                              <div style={{ fontSize: '12px' }}>
                                <FieldTimeOutlined />{' '}
                                {moment(file.toRestoreAt).format('LL')} {'●'}{' '}
                                {timeLeft(file.toRestoreAt)} Days Left
                              </div>
                            </Tooltip>
                          ) : (
                            <div
                              style={{
                                color: colors.red,
                                fontSize: '0.65rem',
                              }}
                            >
                              Status: {file.uploadStatus}, Progress:&nbsp;
                              {(file.uploadProgress * 100).toFixed(0)}%
                            </div>
                          )
                        }
                      />
                    </div>
                  ))
                ) : (
                  <p style={{ textAlign: 'center' }}>Nothing to Display</p>
                )}
              </ListContainer>
            </>
          }
          right={<QuickLinks links={quickLinksArray} />}
        />
      )}
      {showModal && <VideoPlayer src={signedUrl} setShowModal={setShowModal} />}
    </Dashboard>
  );
};

export default ViewLayout;
