import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useDimensions from 'react-use-dimensions';
import LabelSelector from './AnnotateComponents/LabelSelector';
import ObjectSelector from './AnnotateComponents/ObjectSelector';
import VideoPreview from './AnnotateComponents/VideoPreview';
import { getGlobalVideos } from '../Utils/helpers';
import Toolbar from './AnnotateComponents/Toolbar';
import WorkContainer from './AnnotateComponents/WorkContainer';
import ImageSwitcher from './AnnotateComponents/ImageSwitcher';
import Footer from './AnnotateComponents/FooterTools';
import FullScreenBtn from './AnnotateComponents/FullScreenBtn';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

export const WorkSpaceContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0.3rem;
  margin: 0 auto;
  overflow: hidden;
`;

export const RightWorkContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0 1.25rem;
  overflow-y: auto;
`;

export const DrawContainer = styled.div`
  width: 100%;
  padding: 0;
`;

export const SidebarsAndContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const FsToolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.625rem;
  border-left: 0.125rem solid ${colors.medGray};
  border-right: 0.125rem solid ${colors.medGray};
`;

export const InnerSliderContent = styled.div`
  width: 18.75rem;
  height: 100%;
`;

export const SideBarFullScreen = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  border: none;
`;

const emptyAr = [];
const emptyObj = {};

const Workspace = ({
  isSubmitButtonEnabled,
  style = emptyObj,
  toolbarItems = emptyAr,
  selectedTools = ['select'],
  footerItems = emptyAr,
  imageSelector = emptyAr,
  onClickFooterItem,
  onFilterValueUpdate,
  onClickToolbarItem,
  activeImage,
  state,
  useHistory,
  dispatch,
  onSubmit,
  onClickLabel,
  onSelectLabel,
  onChangeLabel,
  onSelectObject,
  onDeleteObject,
  onChangeObject,
  headerLeftSide = null,
  children,
  fullScreen,
  setFullScreen,
}) => {
  const [workContainerRef, workContainerSize] = useDimensions();
  const [hasVideo, setHasVideo] = useState(false);
  const params = useParams();

  getGlobalVideos().then((videos) => {
    if (typeof videos === 'object') {
      if (videos.hasOwnProperty(params.job)) {
        setHasVideo(true);
      } else {
        setHasVideo(false);
      }
    }else{
      setHasVideo(false);
    }
  });

  return (
    <WorkSpaceContainer>
      {fullScreen ? (
        <>
          <FsToolbar>
            <Toolbar
              onClickItem={onClickToolbarItem}
              selectedTools={selectedTools}
              items={toolbarItems}
              activeImage={activeImage}
              width={workContainerSize.width || 0}
              onFilterValueUpdate={onFilterValueUpdate}
              fullScreen={fullScreen}
            />
            <FullScreenBtn
              fullScreen={fullScreen}
              setFullScreen={setFullScreen}
            />
          </FsToolbar>
          <SidebarsAndContent>
            <SideBarFullScreen>
              <InnerSliderContent>
                <LabelSelector
                  state={state}
                  onChangeLabel={onChangeLabel}
                  onSelectLabel={onSelectLabel}
                />
                <ObjectSelector
                  state={state}
                  onSelectObject={onSelectObject}
                  onDeleteObject={onDeleteObject}
                  onChangeObject={onChangeObject}
                />
                {hasVideo && <VideoPreview state={state} />}
              </InnerSliderContent>
            </SideBarFullScreen>

            <DrawContainer className='drawContainer'>
              <WorkContainer ref={workContainerRef} fullScreen={fullScreen}>
                {children}
              </WorkContainer>
              <ImageSwitcher
                dispatch={dispatch}
                useHistory={useHistory}
                state={state}
              />
              <Footer
                isSubmitButtonEnabled={isSubmitButtonEnabled}
                state={state}
                dispatch={dispatch}
                items={footerItems}
                fullScreen={fullScreen}
              />
            </DrawContainer>
          </SidebarsAndContent>
        </>
      ) : (
        <SidebarsAndContent>
          {imageSelector}
          <SideBarContainer>
            <InnerSliderContent>
              <LabelSelector
                state={state}
                onChangeLabel={onChangeLabel}
                onSelectLabel={onSelectLabel}
              />
              <ObjectSelector
                state={state}
                onSelectObject={onSelectObject}
                onDeleteObject={onDeleteObject}
                onChangeObject={onChangeObject}
              />
              {hasVideo && <VideoPreview state={state} />}
            </InnerSliderContent>
          </SideBarContainer>
          <RightWorkContainer className='drawContainer'>
            <Toolbar
              onClickItem={onClickToolbarItem}
              selectedTools={selectedTools}
              items={toolbarItems}
              activeImage={activeImage}
              width={workContainerSize.width || 0}
              onFilterValueUpdate={onFilterValueUpdate}
              fullScreen={fullScreen}
            />
            <WorkContainer ref={workContainerRef} fullScreen={fullScreen}>{children}</WorkContainer>
            <ImageSwitcher
              dispatch={dispatch}
              useHistory={useHistory}
              state={state}
            />
            <Footer
              isSubmitButtonEnabled={isSubmitButtonEnabled}
              state={state}
              dispatch={dispatch}
              items={footerItems}
            />
          </RightWorkContainer>
        </SidebarsAndContent>
      )}
    </WorkSpaceContainer>
  );
};

export default Workspace;
