import React from 'react';
import LogoBlack from '../../Assets/Images/LogoBlack.svg';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const MainContainer = styled.div`
  clear: left;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10rem;
  @media (max-width: 850px) {
    justify-content: center;
    margin: 0;
  }
  @media (max-height: 650px) {
    justify-content: center;
    margin: 0;
  }
`;

const Form = styled.div`
  background-color: ${colors.white};
  border: 0.125rem solid ${colors.teal};
  box-sizing: border-box;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 28rem;
  min-width: 18rem;
`;

const RediMindsLogo = styled.img`
  max-width: 12.5rem;
  margin: 2rem 0;
  @media (max-width: 480px) {
    max-width: 10rem;
  }
`;

const ChildContainer = styled.div`
  margin-bottom: 2rem;
`;

const FormLayout = ({ children }) => {
  return (
    <MainContainer>
      <Form>
        <RediMindsLogo src={LogoBlack} alt='RediMinds Logo' />
        <ChildContainer>{children}</ChildContainer>
      </Form>
    </MainContainer>
  );
};

export default FormLayout;
