import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, notification } from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import ArrowDownRed from '../Assets/Icons/ArrowDownRed.svg';
import SettingsRed from '../Assets/Icons/SettingsRed.svg';
import Logout from '../Assets/Icons/LogOut.svg';
import ProfileDefault from '../Assets/Images/ProfileDefault.svg';
import { Context } from '../Utils/UserContext';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { settingsFlag } from '../Utils/featureFlag';
import { colors } from '../GlobalColors';
import { getUserProfilePicture } from '../Utils/axios';

const Container = styled.div`
  float: right;
  @media screen and (max-width: 1200px) {
    font-size: 0.75rem;
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AvatarContainer = styled.div`
  height: 2.75rem;
  width: 2.7rem;
  overflow: hidden;
  border: 0.125rem solid ${colors.teal};
  border-radius: 50%;
`;

const Avatar = styled.img`
  width: 2.5rem;
  height: 3.125rem;
  box-sizing: border-box;
  object-fit: fill;
`;

const UsernameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserLabel = styled.div`
  color: white;
  padding: 1px;
  font-size: 0.8rem;
  width: 6rem;
  text-align: center;
  border-radius: 1rem;
  margin-left: 0.625rem;
  @media screen and (max-width: 1200px) {
    font-size: 0.4rem;
    width: 3rem;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

const Username = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-align: center;
  color: ${colors.white};
  margin-left: 0.625rem;
  margin-right: 3rem;
  @media screen and (max-width: 1200px) {
    font-size: 0.75rem;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

const Overlay = styled.div`
  padding: 0.625rem 0.625rem 1.875rem 1.875rem;
  background-color: rgba(51, 51, 51, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25vw;
  margin-top: 1.25rem;
  min-width: 18rem;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1.25rem;
  cursor: pointer;
`;

const MenuItemName = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: ${colors.white};
  margin-left: 1.25rem;
`;

const DropdownImage = styled.img`
  cursor: pointer;
  @media (max-width: 900px) {
    display: none;
  }
`;

const UsersDropdown = () => {
  const { logout, user, userImage, setUserImage } = useContext(Context);
  const [dropdownState, setDropdownState] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (user) {
      getUserProfilePicture().then((res) => {
        setUserImage(res);
      });
    } else {
      setUserImage(ProfileDefault);
    }
  }, [setUserImage, history, user]);

  const handleLogout = async () => {
    try {
      logout();
    } catch (err) {
      notification.error({
        message: 'Error',
        description: err,
        placement: 'topRight',
      });
    }
  };

  const menu = (
    <Overlay>
      <MenuItem onClick={() => history.push('/')}>
        <HomeOutlined style={{ color: colors.red, fontSize: 28 }} />
        <MenuItemName>HOME</MenuItemName>
      </MenuItem>
      <MenuItem>
        <Link
          to={settingsFlag ? '/profile' : '/create-password'}
          onClick={() => setDropdownState(false)}
        >
          <UserOutlined style={{ color: colors.red, fontSize: 28 }} />
          <MenuItemName>PROFILE</MenuItemName>
        </Link>
      </MenuItem>
      {user?.roles?.isSuperUser && (
        <MenuItem
          onClick={() => {
            window.location = '/admin';
            setDropdownState(false);
          }}
        >
          <img height={28} width={28} src={SettingsRed} alt='' />
          <MenuItemName>ADMIN</MenuItemName>
        </MenuItem>
      )}
      <MenuItem onClick={() => handleLogout()}>
        <img height={28} width={28} src={Logout} alt='' />
        <MenuItemName>LOGOUT</MenuItemName>
      </MenuItem>
    </Overlay>
  );

  function userLabel() {
    if (user?.roles?.isDoctor) {
      return (
        <UserLabel style={{ backgroundColor: 'green' }}>Radiologist</UserLabel>
      );
    } else if (user?.roles?.isDiagnostic) {
      return (
        <UserLabel
          style={{
            backgroundColor: 'purple',
          }}
        >
          Diagnostic
        </UserLabel>
      );
    } else if (user?.roles?.isSuperPatient) {
      return (
        <UserLabel style={{ backgroundColor: 'deepPink', color: 'black' }}>
          Super Patient
        </UserLabel>
      );
    } else if (user?.roles?.isSupplierAdmin) {
      return (
        <UserLabel style={{ backgroundColor: 'darkRed' }}>
          Super Admin
        </UserLabel>
      );
    } else if (user?.roles?.isSuperUser) {
      return (
        <UserLabel style={{ backgroundColor: 'blue' }}>Super User</UserLabel>
      );
    }
  }

  return (
    <Container>
      <Dropdown
        overlay={menu}
        placement='bottomRight'
        trigger={['click']}
        visible={dropdownState}
        onVisibleChange={() => setDropdownState((state) => !state)}
      >
        <UserContainer>
          <AvatarContainer>
            <Avatar src={userImage} alt='' style={{ height: '2.5rem' }} />
          </AvatarContainer>
          <UsernameContainer>
            <Username>{user?.email ?? null}</Username>
            {userLabel()}
          </UsernameContainer>

          <DropdownImage
            width={24}
            src={ArrowDownRed}
            alt=''
            style={dropdownState ? { transform: 'rotateX(180deg)' } : {}}
          />
        </UserContainer>
      </Dropdown>
    </Container>
  );
};

export default UsersDropdown;
