import axios from 'axios';
import { getAccessToken } from './axios';

const REACT_APP_SEGMENTOR_API_URL = process.env.REACT_APP_SEGMENTOR_API_URL;

export const processSegmentation = async (response, segMode) => {
    let files = response?.data.data.files;
    files.segMode = segMode;
    const origin = response?.data.data.origin;
    const token = await getAccessToken();
    const headers = {
      'Content-Type': 'application/json',
       Authorization: `Bearer ${token}`,
       Origin: origin
       };
    return axios
    .post(
      REACT_APP_SEGMENTOR_API_URL,
      {
        method: 'POST',
        mode: 'cors',
        data: files,
      },
      { headers }
    )
    .catch((e) => {
      console.log('error from segmentation server --->', e);
    });
  };