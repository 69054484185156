import React, { useEffect, useState, useContext } from 'react';
import { Button, Upload, message, notification, Modal, Input } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import UploadStats from './Stats';
import QuickLinks from '../../Components/QuickLinks';
import Dashboard from '../../Components/Dashboard';
import LayoutColumn from '../../Components/LayoutColumn';
import UploadBox from '../../Components/UploadBox';
import EventDropdown from './EventDropdown';
import { checkDuplicates, filterByExtension, getErrorMessage } from './utils';
import {
  getPresignedPostData,
  updateFile,
  getFileTypes,
  getSurgicalEventFiles,
  generateOtpEmailFor3dModal,
} from '../../Utils/axios';
import { getFileTypeFromUrl } from '../../Utils/getMode';
import { colors } from '../../GlobalColors';
import { Context } from '../../Utils/UserContext';

const cancelToken = axios.CancelToken;

const UploadScreen = ({ title, quickLinksArray }) => {
  const { user } = useContext(Context);
  const [stats, setStats] = useState({ files: [] });
  const [images, setImages] = useState([]);
  const [activeUploads, setActiveUploads] = useState([]);
  const [allUploads, setAllUploads] = useState([]);
  const [fileType, setFileType] = useState();
  const [selectedEventId, setSelectedEventId] = useState();
  const [pageStats, setPageStats] = useState({
    total: 0,
    recent: 0,
    active: 0,
  });
  const mode = getFileTypeFromUrl();
  const [source] = useState(cancelToken.source());
  const [sources, setSources] = useState([]);

  const getUploadStats = async () => {
    try {
      getFileTypes().then((res) => {
        let fileTypeList = res?.data ?? [];
        let type;
        if (fileTypeList && fileTypeList.length) {
          type = fileTypeList.find((ft) => ft.name === mode);
        } else {
          type = '';
        }
        setFileType(type);
        getSurgicalEventFiles().then((res) => {
          let all = res?.data ?? [];
          setAllUploads(res?.data ?? []);

          let recent;
          if (all && all.length) {
            const today = new Date(Date.now()).getTime();
            recent = all.filter(
              // 604800000 = milliseconds in 7 days
              (file) => new Date(file.updatedAt).getTime() >= today - 604800000
            );
          } else {
            recent = [];
          }

          if (
            user?.roles?.isDoctor ||
            user?.roles?.isDiagnostic ||
            user?.roles?.isSuperPatient
          ) {
            // for radiologist, diagnostic, super-patient
            let rdcAll;
            if (all && all.length) {
              rdcAll = all.filter((file) => file.uploadedById === user.id);
            } else {
              rdcAll = [];
            }

            let rdcRecent;
            if (rdcAll && rdcAll.length) {
              const today = new Date(Date.now()).getTime();
              rdcRecent = rdcAll.filter(
                // 604800000 = milliseconds in 7 days
                (file) =>
                  new Date(file.updatedAt).getTime() >= today - 604800000
              );
            } else {
              rdcRecent = [];
            }
            setPageStats({
              total: rdcAll?.length ?? 0,
              recent: rdcRecent?.length ?? 0,
              active: activeUploads.length,
            });
          } else {
            setPageStats({
              total: all?.length ?? 0,
              recent: recent?.length ?? 0,
              active: activeUploads.length,
            });
          }
        });
      });
    } catch (e) {
      console.error('Error in fetching stats', e);
    }
  };

  const cancelUpload = (file) => {
    if (sources.length) {
      const sourceWithFile = sources.find(
        (sourceObj) => sourceObj?.file?.name === file?.name
      );
      console.log(source, 'source');
      sourceWithFile?.source?.cancel();
      const newActive = activeUploads.filter((f) => f.name !== file.name);
      setActiveUploads(newActive);
      updateFile(
        file?.originFileObj?.surgicalEventFileId,
        file?.originFileObj?.progress / 100,
        'canceled'
      );
    }
  };

  useEffect(() => {
    getUploadStats();
  }, []);

  const uploadSurgicalFile = async ({ file }) => {
    if (file) {
      let image = images.find((i) => i.name === file.name);

      try {
        const {
          data: { data },
          status,
        } = await getPresignedPostData(image, selectedEventId, fileType.id);
        if (data && status && status === 200) {
          const source = cancelToken.source();
          setSources((existingSources) => {
            return [
              ...existingSources,
              {
                file,
                source,
              },
            ];
          });
          await uploadFileToS3(data, image, source);
        }
      } catch (err) {
        notification.error({
          message: 'Error',
          description: err.message,
          placement: 'topRight',
        });
      }
    }
  };

  const uploadFileToS3 = (data, file, source) => {
    return new Promise((resolve, reject) => {
      updateFile(data.surgicalEventFileId, 0, 'pending');
      const { files } = stats;
      let currentFile = files.find((f) => f.name === file.name);
      currentFile.surgicalEventFileId = data.surgicalEventFileId;

      axios({
        method: 'PUT',
        url: data.presignedPostData.url,
        data: file,
        cancelToken: source?.token ?? undefined,
        onUploadProgress: (progressEvent) => {
          let percentCompleted = progressEvent.loaded / progressEvent.total;
          currentFile.progress = Math.round(percentCompleted * 100);
          setStats({ files: files });
          updateFile(
            data?.surgicalEventFileId,
            percentCompleted,
            'in progress'
          );
        },
      })
        .then((res) => {
          updateFile(res?.config?.data?.surgicalEventFileId, 1, 'complete');
          resolve(res);
          let newActive = activeUploads.filter((img) => img.progress !== 100);
          setActiveUploads(newActive);
          // The db/cloud is taking time to update stats. To counter that using this time delay.
          setTimeout(() => {
            getUploadStats();
            message.success(`${file.name} uploaded successfully.`);
          }, 4000);
        })
        .catch((err) => {
          notification.error({
            message: 'Error',
            description: 'Upload failed',
            placement: 'topRight',
          });
          updateFile(data?.surgicalEventFileId, 0, 'failed');
          reject(err);
        });
    });
  };

  const handleChange = ({ fileList }) => {
    let { filesToUpload } = filterByExtension(fileList);
    let activeList = filesToUpload.filter((f) => f.status === 'uploading');
    setActiveUploads(activeList);
  };

  const beforeUpload = (file, fileList) => {
    let { filesToUpload, filesToDiscard } = filterByExtension(fileList);
    if (filesToDiscard.length) {
      let res = filesToDiscard.find((f) => f.name === file.name);
      if (res) {
        notification.error({
          message: res.name,
          description: getErrorMessage(res),
          placement: 'bottomLeft',
          duration: 30,
        });
      }
    }
    if (filesToUpload.length) {
      let files = filesToUpload;
      for (let i = 0; i < filesToUpload.length; i++) {
        let isDuplicate = checkDuplicates(filesToUpload[i], allUploads);
        if (isDuplicate) {
          files = filesToUpload.filter((f) => f.name !== file.name);
        }
      }
      if (files?.length) {
        setStats({ files: files });
        setImages(files);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <Dashboard pageTitle={title}>
      <LayoutColumn
        left={
          <>
            <Upload
              multiple={true}
              disabled={selectedEventId === undefined}
              fileList={activeUploads}
              beforeUpload={beforeUpload}
              customRequest={uploadSurgicalFile}
              onChange={handleChange}
            >
              <UploadBox disabled={selectedEventId === undefined} />
            </Upload>
            <div style={{ fontSize: '1rem', marginTop: '1rem' }}>
              <ExclamationCircleOutlined style={{ color: colors.teal }} />{' '}
              Select a Surgical Event to start uploading
            </div>
            <EventDropdown
              selectedEventId={selectedEventId}
              setSelectedEventId={setSelectedEventId}
            />
          </>
        }
        right={
          <>
            <UploadStats
              pageStats={pageStats}
              activeUploads={activeUploads}
              cancelUpload={(file) => {
                cancelUpload(file);
              }}
            />
            <QuickLinks links={quickLinksArray} />
          </>
        }
      />
    </Dashboard>
  );
};

export default UploadScreen;
