import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LinkButtonContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default LinkButtonContainer;
