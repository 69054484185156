import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Play from '../../../Assets/Icons/Play.svg';
import VideoPlayer from '../../../Components/VideoPlayer';
import { getSignedUrl, getGlobalVideos } from '../../Utils/helpers';
import styled from 'styled-components';

const PreviewContainer = styled.div`
  position: relative;
  margin: 0 auto;
`;

const PreviewImage = styled.img`
  height: 100%;
  width: 100%;
  opacity: 0.5;
`;

const PlayIcon = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 3rem;
  :hover {
    filter: invert(43%) sepia(91%) saturate(7495%) hue-rotate(341deg)
      brightness(94%) contrast(94%);
  }
`;
const VideoPreview = ({ state }) => {
  console.log("prrrreeevvvviiieewww====",getSignedUrl());
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const [videoSignedUrl, setVideoSignedUrl] = useState(false);
  const [timeStart, setTimeStart] = useState(0);

  const { images, selectedImage } = state;

  useEffect(() => {
    getGlobalVideos()
      .then((globalVideos) => {
        setVideoSignedUrl(getSignedUrl(globalVideos[params.job].S3URI));
        let frameNumber = params.asset.match(/([\d]+)/g);
        setTimeStart(
          parseInt(frameNumber[0]) /
            parseInt(globalVideos[params.job].frameRate)
        );
      })
      .catch((err) => console.log(err));
  }, [params]);

  return (
    <>
      <PreviewContainer>
        <PreviewImage src={getSignedUrl(images[selectedImage].src)} />
        <PlayIcon src={Play} onClick={() => setShowModal(true)} />
      </PreviewContainer>
      {showModal ? (
        <VideoPlayer
          src={videoSignedUrl}
          timeStart={timeStart}
          setShowModal={setShowModal}
        />
      ) : null}
    </>
  );
};

export default VideoPreview;
