import React from 'react';
import styled from 'styled-components';
import { colors } from '../GlobalColors';

const Mask = styled.div`
  z-index: 999;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.transparentBlack};
`;

const Container = styled.div`
  z-index: 1000;
  position: absolute;
  width: 40rem;
  min-width: 16rem;
  height: 25rem;
  border-radius: 1rem;
  background-color: ${colors.white};
  box-shadow: ${colors.transparentBlack} 0 0.3rem 1rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  @media (max-width: 768px) {
    width: 80%;
    height: fit-content;
    top: 32%
  }
`;

const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 1.75rem;
  text-align: center;
  margin: 2rem 0;
`;

const Body = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  position: relative;
`;

const Text = styled.p`
  font-size: 1rem;
  max-height: 100%;
`;

const Footer = styled.div`
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = ({ icon, title, body, buttons }) => {
  return (
    <Mask>
      <Container>
        {icon && <img src={icon} alt={`${title}`} height={100} />}
        {title && <Title>{title}</Title>}
        {body && (
          <Body>
            <Text>{body}</Text>
          </Body>
        )}
        <Footer>{buttons}</Footer>
      </Container>
    </Mask>
  );
};

export default Modal;
