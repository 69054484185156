import React from 'react';
import Heading from '../../Components/Heading';
import Pagination from '../Pagination';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow-y: auto;
  overflow-x: auto;
  word-wrap: break-word;
  border-radius: 10px 10px 0 0;
  width: 32vw;
  background-color: ${(props) => (props.isDoctor ? '#fafafa' : 'none')};
  padding: 0px 4px 4px 4px;
  box-shadow: ${(props) =>
    props.isDoctor
      ? '-3px -3px 4px rgba(0, 0, 0, 0.1) inset, 1px 0 2px rgba(0, 0, 0, 0.1) inset'
      : 'none'};
  @media screen and (max-width: 1200px) {
    width: 80vw;
    min-width: 16rem;
  }
`;

const Content = styled.div`
  text-align: left;
  flex-grow: 1;
`;

const DoctorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
`;

const DoctorSubContainer = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  background-color: ${(props) => (props.active ? '#93c5724d' : '#93c5721a')};
  color: ${(props) => (props.active ? 'white' : 'inherit')};
  box-shadow: ${(props) =>
    props.active ? '0 0 8px 0 rgba(0, 0, 0, 0.5)' : 'none'};
  border-bottom: ${(props) => (props.active ? 'none' : '2px solid #93c5721a')};
`;

const ListContainer = ({
  title,
  children,
  paginationData,
  isDoctor,
  requestedFiles,
  setRequestedFiles,
}) => {
  const {
    firstIndex,
    lastIndex,
    numberItems,
    itemsPerPage,
    currentPage,
    setCurrentPage,
  } = paginationData;

  return (
    <Container isDoctor={isDoctor}>
      <Content>
        {isDoctor ? (
          <DoctorContainer>
            <DoctorSubContainer
              active={!requestedFiles}
              onClick={() => setRequestedFiles(false)}
            >
              <Heading>My Uploads</Heading>
            </DoctorSubContainer>
            <DoctorSubContainer
              active={requestedFiles}
              onClick={() => setRequestedFiles(true)}
            >
              <Heading>Requested 3D</Heading>
            </DoctorSubContainer>
          </DoctorContainer>
        ) : (
          <Heading>{title}</Heading>
        )}
        {children}
      </Content>
      <Pagination
        firstIndex={firstIndex}
        lastIndex={lastIndex}
        numberItems={numberItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Container>
  );
};

export default ListContainer;
