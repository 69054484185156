import React from 'react';
import { useHistory } from 'react-router-dom';
import UploadVideos from '../../Assets/Icons/Video.svg';
import UploadImaging from '../../Assets/Icons/Heart.svg';
import LinkButton from '../../Components/Buttons/LinkButton';
import LinkButtonContainer from '../../Components/Buttons/LinkButtonContainer';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const Modal = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  clear: left;
  margin: 0 auto;
  width: 40rem;
  height: 25rem;
  border-radius: 1rem;
  border: 0.125rem solid ${colors.teal};
  background-color: ${colors.white};
  box-shadow: ${colors.transparentBlack} 0 0.3rem 1rem;
  padding: 1.5rem;
  min-width: 12rem;
  @media (max-width: 768px) {
    width: 80%;
    height: fit-content;
    top: 32%;
  }
`;

const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 1.75rem;
  text-align: center;
  margin: 2rem 0;
`;

const Prompt = () => {
  const history = useHistory();
  return (
    <Modal>
      <Title>Are you here to work with:</Title>
      <LinkButtonContainer>
        <LinkButton
          onClick={() => history.push('/video')}
          imgsrc={UploadVideos}
          buttontext='Surgical Videos'
        />
        <LinkButton
          onClick={() => history.push('/imaging')}
          imgsrc={UploadImaging}
          buttontext='Medical Imaging'
        />
      </LinkButtonContainer>
    </Modal>
  );
};

export default Prompt;
