import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const GtfLogo = styled.div`
  display: flex;
  font-weight: 900;
  flex-direction: row;
  font-size: 2.5rem;
  justify-content: center;
  text-align: center;
  @media (max-width: 1024px) {
    clear: both;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
`;

const InlineLogo = () => {
  return (
    <Link to='/'>
      <GtfLogo>
        <span style={{ color: colors.white, marginRight: '0.5rem' }}>Ground Truth</span>{' '}
        <span style={{ color: colors.red }}>Factory</span>
      </GtfLogo>
    </Link>
  );
};

export default InlineLogo;
