import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const IconNameDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Item = styled.div`
  border-bottom: 0.063rem solid ${colors.medGray};
  margin-bottom: 1.25rem;
  width: 100%;
`;

const ItemName = styled.div`
  color: ${colors.darkGray};
  cursor: pointer;
  word-break: break-word;
  :hover {
    color: ${colors.red};
    font-weight: bold;
  }
  @media screen and (max-width: 1200px) {
    text-align: center;
  }
`;

const TimeStamp = styled.div`
  color: ${colors.teal};
  font-size: 0.625rem;
  margin-top: 0.188rem;
  margin-bottom: 1rem;
  text-align: left;
`;

const ListItem = ({ icon, name, onClickFile, rightContent, dateText, leftContent, centerContent }) => {
  return (
    <Item>
      <div>
        <Row>
          <Col span={22}>
            <IconNameDiv>
              {icon}
              <ItemName onClick={onClickFile}>{name}</ItemName>
            </IconNameDiv>
          </Col>
          <Col span={1} offset={1}>
            {centerContent}
          </Col>
          <Col span={1} offset={1}>
            {leftContent}
          </Col>
          <Col span={1} offset={1}>
            {rightContent}
          </Col>
          <TimeStamp>{dateText}</TimeStamp>
        </Row>
      </div>
    </Item>
  );
};

export default ListItem;
