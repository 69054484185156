import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const GtfLogo = styled.div`
  clear: both;
  display: flex;
  flex-direction: column;
  float: left;
  font-weight: 900;
  font-size: 4rem;
  @media (max-height: 650px) {
    flex-direction: row;
    font-size: 2.5rem;
    justify-content: center;
    float: none;
  }
  @media (max-width: 850px) {
    font-size: 2.5rem;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    float: none;
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
`;

const StackedLogo = () => {
  return (
    <Link to='/'>
      <GtfLogo>
        <span style={{ color: colors.white, marginRight: '0.5rem' }}>
          Ground Truth
        </span>
        <span style={{ color: colors.red }}>Factory</span>
      </GtfLogo>
    </Link>
  );
};

export default StackedLogo;
