import React, { useState } from 'react';
import {
  SecurityScanFilled,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import styled, { keyframes } from 'styled-components';
import {
  get3dModelFileByIdPatient,
  verifySecretFor3dModal,
} from '../../Utils/axios';
import { notification } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
`;

const FilledCircle = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #0096f399;
  display: flex;
  align-items: center;
  justify-content: center;
  &.loading {
    animation-play-state: running;
    animation: ${rotate} 2s linear infinite;
  }
`;

const Container = styled.div`
  background-color: #00f3861a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 30px;
  margin: 15%;
  max-width: 1000px;
  min-width: 400px;
  text-align: center;
  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

const CodeContainer = styled.div`
  display: flex;
  margin: 40px 0;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

const VerifyButton = styled.div`
  min-width: 150px;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  margin: 0px 0px 20px 0px;
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 4px;
  background-color: #00f386;
  border-color: #9861c2;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const View3dModalPatient = (props) => {
  const data = props.location.search.split('?').pop();
  const decodedSearch = decodeURIComponent(data);

  const searchArr = decodedSearch.split('&');

  const encodedSecret = searchArr[0].split('=').pop();
  const secret = '+' + encodedSecret;

  const fileId = searchArr[1].split('=').pop();

  const [correctSecret, setCorrectSecret] = useState('check'); // 'check' 'yes' 'no'
  const [loading, setLoading] = useState(false);
  const [patientPhone, setPatientPhone] = useState('');

  const iconComponent = (correctSecret) => {
    switch (correctSecret) {
      case 'check':
        return (
          <SecurityScanFilled
            style={{ fontSize: '45px', color: '#f30051b3' }}
          />
        );
      case 'yes':
        return (
          <CheckOutlined style={{ fontSize: '45px', color: '#f30051b3' }} />
        );
      case 'no':
        return (
          <CloseOutlined style={{ fontSize: '45px', color: '#f30051b3' }} />
        );
      default:
        return (
          <SecurityScanFilled
            style={{ fontSize: '45px', color: '#f30051b3' }}
          />
        );
    }
  };

  const h1Component = (correctSecret) => {
    switch (correctSecret) {
      case 'check':
        return 'Verify Your Access';
      case 'yes':
        return 'You now have access to view your 3D model!';
      case 'no':
        return 'Sorry! Entered phone number was incorrect';
      default:
        return (
          <SecurityScanFilled
            style={{ fontSize: '45px', color: '#f30051b3' }}
          />
        );
    }
  };

  const verifySecretFn = async () => {
    if (patientPhone.length < 2) {
      notification.error({
        message: 'Secret Phone Number missing!',
      });
    } else {
      const cpatientPhone = `+${patientPhone}`;
      const phoneUtil = PhoneNumberUtil.getInstance();
      try {
        setLoading(true);
        const parsedNumber = phoneUtil.parse(cpatientPhone);
        const isValidPhone = phoneUtil.isValidNumber(parsedNumber);
        if (!isValidPhone) {
          notification.error({
            message: 'Entered secret phone number is invalid!',
          });
          setCorrectSecret('no');
          setLoading(false);
        } else {
          const res = await verifySecretFor3dModal(`${fileId}-${patientPhone}`);
          if (res?.data) {
            setCorrectSecret('yes');
            setLoading(false);
          } else {
            setCorrectSecret('no');
            setLoading(false);
            notification.error({
              message: 'Entered secret phone number is incorrect!',
            });
          }
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const view3dModalFn = async () => {
    const signed = await get3dModelFileByIdPatient(fileId).then((res) => {
      return res?.data?.signedUrl;
    });
    window.location = `/3d-viewer/website/#model=${signed}`;
  };

  return (
    <Container>
      <FilledCircle className={loading ? 'loading' : ''}>
        {iconComponent(correctSecret)}
      </FilledCircle>
      <h1 style={{ color: 'white', fontWeight: 'bold' }}>
        {h1Component(correctSecret)}
      </h1>
      {correctSecret === 'yes' ? (
        ''
      ) : (
        <h3 style={{ color: 'lightYellow', letterSpacing: '2px' }}>
          Please enter your phone number {secret} in order to view your 3d model
        </h3>
      )}
      {correctSecret === 'yes' ? (
        ''
      ) : (
        <CodeContainer>
          <PhoneInput
            name='phone'
            type='number'
            placeholder='Enter your Phone'
            country={'us'}
            preferredCountries={['us', 'in', 'ca']}
            //onlyCountries={['us']}
            //disableCountryCode={true}
            containerStyle={{
              width: '300px',
              height: '40px',
              fontSize: '20px',
            }}
            inputStyle={{
              width: '300px',
              height: '40px',
              fontSize: '20px',
            }}
            value={patientPhone}
            onChange={(e) => setPatientPhone(e)}
          />
        </CodeContainer>
      )}
      <VerifyButton
        disabled={loading}
        onClick={correctSecret === 'yes' ? view3dModalFn : verifySecretFn}
      >
        {loading
          ? 'Please wait...'
          : correctSecret === 'yes'
          ? 'View 3d model'
          : 'Verify'}
      </VerifyButton>
    </Container>
  );
};

export default View3dModalPatient;
