import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { notification, Modal, message } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import AWS from 'aws-sdk';
import 'aws-sdk/clients/sagemaker';
import {
  splitS3Url,
  getManifestData,
  filterInputBucket,
  // getGlobalJobs,
} from '../Utils/helpers';
import { outputManifest } from '../Utils/outputManifest';
import { labels } from '../Utils/labels';
import Dashboard from '../../Components/Dashboard';
import Spinner from '../../Components/Spinner';
import FullScreenBtn from '../AnnotatePage/AnnotateComponents/FullScreenBtn';
import SearchBar from '../../Components/SearchBar';
import Annotator from './index';
import {
  getSagemakerJobsByName,
  getGlobalJobsJson,
  getJobMainfest,
  getSignedUrl,
  getS3UploadUrl,
} from '../../Utils/axios';
import axios from 'axios';

const { confirm } = Modal;

const AnnotateTool = () => {
  const [fullScreen, setFullScreen] = useState(false);
  const [images, setImages] = useState([{ regions: [] }]);
  const [imageRegions, setImageRegions] = useState([]);
  const [outputBucket, setOutputBucket] = useState('');
  const [inputBucket, setInputBucket] = useState('');
  let [assetMeta, setAssetMeta] = useState({});
  let [globalJobs, setGlobalJobs] = useState({});
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [search, setSearch] = useState('');
  let [jobAssets, setJobAssets] = useState([]);

  const jobParams = useParams();
  const history = useHistory();

  const prepareManifestData = async (listUrl) => {
    // Filter manifest text response and return list.
    let _listUrl = listUrl.split('{"source-ref":"');
    let resourceList = [];

    _listUrl.map((list, index) => {
      let _url = list.split('"}');
      if (_url.length === 2) {
        let _name = _url[0].split('/');
        let objName = _name.pop();
        resourceList.push({
          jobName: jobParams.job,
          key: index,
          name: objName,
          src: _url[0],
          regions: [],
        });
      }
      return list;
    });
    setImages(resourceList);
  };

  const getJobManifest = async (bucketUrl) => {
    try {
      const data = await getJobMainfest(bucketUrl);
      const manifestUrl = data?.data?.data;
      if (manifestUrl) {
        let urlData = await getManifestData(manifestUrl);
        await prepareManifestData(urlData);
      }
    } catch (err) {
      setJobAssets([]);
      notification.error({ message: 'Resource not found.' });
    }
  };
  // ground-truth-factory-development-input-2
  const getJobAssetMeta = async (outputBucket) => {
    let jsonObjectUrl = splitS3Url(outputBucket);
    if (jsonObjectUrl) {
      let jsonObjectKey = `${jsonObjectUrl.groups.key}${jobParams.job}/${jobParams.job}-meta.json`;
      let jsonSignedUrl = await getSignedUrl({
        bucket: jsonObjectUrl.groups.bucket,
        key: jsonObjectKey,
      });
      await fetch(jsonSignedUrl)
        .then((response) => response.json())
        .then((data) => {
          setAssetMeta(data);
        })
        .catch(() => setAssetMeta({}));
    }
  };

  const describeLabelingJob = async (jobParams) => {
    try {
      const { data } = await getSagemakerJobsByName(jobParams.job);
      const inputConfig = data?.data?.InputConfig;
      const outputConfig = data?.data?.OutputConfig;
      filterInputBucket(
        inputConfig?.DataSource?.S3DataSource?.ManifestS3Uri,
        setInputBucket
      );
      setOutputBucket(outputConfig?.S3OutputPath);
      await getJobManifest(
        inputConfig?.DataSource?.S3DataSource?.ManifestS3Uri
      );
    } catch (err) {
      notification.error({ message: err.message });
    }
  };

  const getImageData = async (outputBucket) => {
    let jsonObjectName = jobParams.asset.split('.');
    jsonObjectName = `${jsonObjectName[0]}.json`;
    let jsonObjectUrl = splitS3Url(outputBucket);
    if (jsonObjectUrl) {
      let jsonObjectKey = `${jsonObjectUrl.groups.key}${jobParams.job}/annotations/unconsolidated-annotation/output/${jsonObjectName}`;
      let jsonSignedUrl = await getSignedUrl({
        bucket: jsonObjectUrl.groups.bucket,
        key: jsonObjectKey,
      });
      await fetch(jsonSignedUrl)
        .then((response) => response.json())
        .then((data) => {
          setImageRegions([...data.regions]);
        })
        .catch(() => setImageRegions([]));
    }
  };

  const saveImage = async (params, base64PngData) => {
    message.loading('Saving..', 2.5);
    let bucketKey = splitS3Url(outputBucket);
    let _image = base64PngData.split('base64,');
    let imageBuffer = new Buffer(_image[1], 'base64');
    let resourceName = params.name.split('.');
    let jsonBlob = new Blob(
      [
        JSON.stringify(
          {
            dimen: params.dimen,
            jobName: params.jobName,
            label: params.label,
            name: params.name,
            pixelSize: params.pixelSize,
            regions: params.regions,
            src: params.src,
          },
          null,
          2
        ),
      ],
      { type: 'application/json' }
    );

    let now = new Date();

    assetMeta[params.name] = {
      name: params.name,
      updatedAt: now.toISOString(),
      numberOfLabels: params.regions.length,
    };

    assetMeta['lastAsset'] = {
      name: params.name,
      updatedAt: now.toISOString(),
    };

    setAssetMeta(assetMeta);

    const metaJsonBlob = new Blob([JSON.stringify(assetMeta, null, 2)], {
      type: 'application/json',
    });

    const bucketWithJob = `${bucketKey.groups.key}${params.jobName}`;

    try {
      const data = [];
      const unconsolidated = await getS3UploadUrl({
        contentType: 'application/json',
        bucket: `${bucketKey.groups.bucket}`,
        key: `${bucketWithJob}/annotations/unconsolidated-annotation/output/${resourceName[0]}.json`,
      });
      data.push({ url: unconsolidated.data.data.url, data: jsonBlob });

      const metaJsonFile = await getS3UploadUrl({
        contentType: 'application/json',
        bucket: `${bucketKey.groups.bucket}`,
        key: `${bucketWithJob}/${params.jobName}-meta.json`,
      });
      data.push({ url: metaJsonFile.data.data.url, data: metaJsonBlob });

      const consolidated = await getS3UploadUrl({
        contentType: 'image/png',
        bucket: `${bucketKey.groups.bucket}`,
        key: `${bucketWithJob}/annotations/consolidated-annotation/output/${resourceName[0]}.png`,
      });
      data.push({ url: consolidated.data.data.url, data: imageBuffer });

      await uploadFileToS3(data);
    } catch (e) {
      message.error('Error saving....');
    }
  };

  const uploadFileToS3 = async (data) => {
    try {
      const promise = await Promise.all(
        data.map(async (key) => {
          return await axios({
            method: 'PUT',
            url: key.url,
            data: key.data,
          });
        })
      );

      notification.success({ message: 'Image saved successfully.' });
    } catch (err) {
      console.error(err, 'ERR');
    } finally {
    }
  };

  const submitImage = async (params) => {
    let consolidateManifest = [];
    let now = new Date();
    params.images.map((image) => {
      let resourceName = image.name.split('.');
      let outputManifestParams = {
        sourceRef: `${inputBucket}/${image.name}`,
        outputSourceRef: `${outputBucket}${image.jobName}/annotations/consolidated-annotation/output/${resourceName[0]}.png`,
        creationDate: now.toISOString(),
        jobName: image.jobName,
      };
      consolidateManifest.push(outputManifest(outputManifestParams));
      return image;
    });

    let s3 = new AWS.S3({ apiVersion: '2020-11-09' });
    let bucketKey = splitS3Url(outputBucket);
    let textBlob = new Blob([JSON.stringify(consolidateManifest, null, 1)], {
      type: 'text/plain',
    });

    const _paramsManifest = {
      Body: textBlob,
      Bucket: `${bucketKey.groups.bucket}/${bucketKey.groups.key}${params.jobName}/manifests/output`,
      Key: 'output.manifest',
    };

    await s3.putObject(_paramsManifest, function (err) {
      if (err) {
        notification.error({ message: "Couldn't save job data successfully." });
      }
    });

    globalJobs[params.jobName] = {
      name: params.jobName,
      completedAt: now.toISOString(),
    };

    setGlobalJobs(globalJobs);

    const jobBlob = new Blob([JSON.stringify(globalJobs, null, 1)], {
      type: 'application/json',
    });

    const _paramsJobs = {
      Body: jobBlob,
      Bucket: `${process.env.REACT_APP_AWS_BUCKET}/global`,
      Key: 'jobs.json',
    };

    await s3.putObject(_paramsJobs, function (err) {
      if (err) {
        notification.error({ message: "Couldn't job save successfully." });
      } else {
        notification.success({ message: 'Job submitted successfully.' });
        history.push('/video/annotate');
      }
    });
  };

  const showSubmitConfirm = (params) => {
    confirm({
      title: '',
      icon: <WarningOutlined />,
      content:
        'When you click the submit button, you will lose the ability to edit the labels.',
      okText: 'Submit',
      width: 500,
      onOk() {
        submitImage(params);
      },
    });
  };

  const imageIndex = images.findIndex(
    (image) => image.name === jobParams.asset
  );

  useEffect(() => {
    describeLabelingJob(jobParams);
  }, []);

  useEffect(() => {
    getImageData(outputBucket);
    getJobAssetMeta(outputBucket);
  }, [jobParams, outputBucket]);

  // useEffect(() => {
  //   getGlobalJobs()
  //     .then((completedJobs) => setGlobalJobs(completedJobs))
  //     .catch(() => setGlobalJobs({}));
  // }, []);
  useEffect(() => {
    getGlobalJobsJson()
      .then((completedJobs) => setGlobalJobs(completedJobs))
      .catch(() => setGlobalJobs({}));
  }, []);

  useEffect(() => {
    setEnableSubmit(Object.keys(assetMeta).length === images.length + 1);
  }, [images, assetMeta]);

  useEffect(() => {
    if (globalJobs.hasOwnProperty(jobParams.job)) {
      history.push('/video/annotate');
    }
  }, [globalJobs, history, jobParams.job]);

  const searchImages = (value) => {
    if (value > 0 && value <= (images && images.length)) {
      let _image = images && images[value - 1];
      history.push(`/video/annotate/${_image.jobName}/${_image.name}`);
    } else if (value === '') {
      return;
    } else {
      notification.error({ message: `Frame ${value} not found.` });
    }
  };

  return (
    <>
      <Dashboard
        pageTitle='Annotate'
        prev={`/video/annotate/${jobParams.job}`}
        fullScreen={fullScreen}
        tools={
          fullScreen ? null : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <FullScreenBtn
                fullScreen={fullScreen}
                setFullScreen={setFullScreen}
              />
              <SearchBar
                placeholder='Search Frames...'
                searchValue={search}
                onClick={(e) => {
                  e.preventDefault();
                  searchImages(search);
                }}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => setSearch('')}
                showClear={search !== ''}
              />
            </div>
          )
        }
      >
        {images.length > 1 ? (
          <Annotator
            images={images}
            regionClsList={labels}
            jobName={jobParams.job}
            selectedImage={imageIndex >= 0 ? imageIndex : 0}
            selectedImageRegion={imageRegions}
            useHistory={() => history}
            renderError={(error) => {
              notification.error({ message: error });
            }}
            onSave={(selectImage, base64PngData) =>
              saveImage(selectImage, base64PngData)
            }
            isSubmitButtonEnabled={enableSubmit}
            onSubmit={(state) => showSubmitConfirm(state)}
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
          />
        ) : (
          <Spinner />
        )}
      </Dashboard>
    </>
  );
};

export default AnnotateTool;
