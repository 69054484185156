import React, { useState } from 'react';
import { Select, Checkbox } from 'antd';
import SearchBar from './SearchBar';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledSelect = styled(Select)`
  width: 15rem;
  margin-left: 2rem;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 2rem;
  }
`;

const ToolBar = ({
  isSuperUser,
  userSupplier,
  canFilterByUploader,
  surgicalEvents,
  dataSuppliers,
  setShowFailed,
  setFilter,
  onClearFilter,
  search,
  setSearch,
  setPageOne,
  showFilter,
  showCheck,
}) => {
  const [showClear, setShowClear] = useState(false);

  return (
    <Container>
      {showCheck ? (
        <Checkbox onChange={() => setShowFailed((state) => !state)}>
          Show Incomplete Uploads
        </Checkbox>
      ) : (
        ''
      )}
      <SearchBar
        placeholder='Search Files...'
        searchValue={search}
        onChange={(e) => {
          setSearch(e.target.value);
          if (e.target.value !== '') {
            setPageOne();
            setShowClear(true);
          } else {
            setPageOne();
            setShowClear(false);
          }
        }}
        onClear={() => {
          setPageOne();
          setSearch('');
          setShowClear(false);
        }}
        showClear={showClear}
      />
      {!showFilter && (
        <StyledSelect
          allowClear
          placeholder='Filter Results'
          onSelect={setFilter}
          dropdownMatchSelectWidth={false}
          onClear={onClearFilter}
        >
          {isSuperUser && (
            <Select.OptGroup label='Admin Only'>
              <Select.Option value='all'>All Uploads</Select.Option>
            </Select.OptGroup>
          )}
          {canFilterByUploader && (
            <Select.OptGroup label='By Uploader'>
              <Select.Option value='mine'>
                All {userSupplier?.name} Uploads
              </Select.Option>
              <Select.Option value='my uploads'>My Uploads</Select.Option>
            </Select.OptGroup>
          )}
          {isSuperUser
            ? dataSuppliers && (
                <Select.OptGroup label='By Data Supplier'>
                  {dataSuppliers.map((supplier) => (
                    <Select.Option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              )
            : surgicalEvents && (
                <Select.OptGroup label='By Surgical Event'>
                  {surgicalEvents.map((event) => (
                    <Select.Option key={event.id} value={event.id}>
                      {event.display}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              )}
        </StyledSelect>
      )}
    </Container>
  );
};

export default ToolBar;
