import React from 'react';
import { FileImageOutlined } from '@ant-design/icons';
import ViewLayout from './index';

const RestoreImaging = () => {
  return (
    <>
      <ViewLayout
        pageTitle='Restore Imaging'
        prevUrl='/imaging'
        listIcon={<FileImageOutlined style={{ marginRight: '0.5rem' }} />}
        quickLinksArray={['uploadImaging', 'viewImaging', 'home']}
      />
    </>
  );
};

export default RestoreImaging;
