import React from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import { FolderOpenOutlined } from '@ant-design/icons';
import Play from '../../Assets/Icons/Play.svg';
import Ellipsis from '../../Assets/Icons/Ellipsis.svg';
import Clock from '../../Assets/Icons/Clock.svg';
// import Frames from '../../Assets/Icons/Frames.svg';
// import FramesAnnotated from '../../Assets/Icons/FramesAnnotated.svg';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const PreviewContainer = styled.div`
  width: 30%;
  margin: 0 auto;
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const VideoDiv = styled.div`
  background: ${colors.darkGray};
  width: 100%;
  max-width: 18rem;
  height: 10rem;
  position: relative;
  @media (max-width: 900px) {
    min-width: 12rem;
    margin: 0 auto;
  }
`;

const PlayButton = styled.img`
  margin-top: 3rem;
  cursor: pointer;
  width: 3rem;
  :hover {
    filter: invert(43%) sepia(91%) saturate(7495%) hue-rotate(341deg)
      brightness(94%) contrast(94%);
  }
`;

const FolderButton = styled(FolderOpenOutlined)`
  margin-top: 3rem;
  cursor: pointer;
  font-size: 3rem;
  color: ${colors.teal};
  :hover {
    color: ${colors.red};
  }
`;

const NameDiv = styled(Row)`
  background: ${colors.teal};
  opacity: 0.8;
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  overflow: hidden;
  text-align: left;
  span {
    word-break: break-word;
  }
`;

const InfoDiv = styled.div`
  margin: 1.5rem 0 4rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 100%;
  max-width: 18rem;
  font-size: 1rem;
  img {
    width: 3rem;
    margin-right: 0.5rem;
  }
  div {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    text-align: left;
  }
  @media (max-width: 900px) {
    width: 15rem;
    margin: 0.5rem auto;
  }
  @media (max-width: 400px) {
    font-size: 0.75rem;
  }
`;

const InfoTitle = styled.div`
  font-weight: bold;
  color: ${colors.darkGray};
`;

const InfoText = styled.div`
  color: ${colors.navy};
  font-weight: bold;
  @media (max-width: 900px) {
    font-size: 1rem;
  }
`;

const Preview = ({ file, fileName, onClick }) => {
  const location = window.location.pathname;
  return (
    <PreviewContainer>
      <VideoDiv>
        {location.includes('video') ? (
          <PlayButton src={Play} alt='video preview' onClick={onClick} />
        ) : (
          <FolderButton alt='image preview' onClick={onClick} />
        )}

        <NameDiv>
          <Col span={22}>
            <span>{fileName}</span>
          </Col>
          <Col span={2}>
            <img src={Ellipsis} alt='' />
          </Col>
        </NameDiv>
      </VideoDiv>
      <InfoDiv>
        <img src={Clock} alt='' />
        <div>
          <InfoTitle>Uploaded</InfoTitle>
          <InfoText>{moment(file.updatedAt).format('l')}</InfoText>
        </div>
      </InfoDiv>
      {/* <InfoDiv>
          <img src={Frames} alt='' />
          <div>
            <InfoTitle>No. of Frames</InfoTitle>
            <InfoText>644</InfoText>
          </div>
        </InfoDiv>
        <InfoDiv>
          <img src={FramesAnnotated} alt='' />
          <div>
            <InfoTitle>Frames Annotated</InfoTitle>
            <InfoText>233</InfoText>
          </div>
        </InfoDiv> */}
    </PreviewContainer>
  );
};

export default Preview;
