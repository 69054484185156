import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getFileTypeFromUrl } from '../../Utils/getMode';
import UploadModal from './UploadModal';
import NoData from '../../Components/NoData';
import LinkButton from '../../Components/Buttons/LinkButton';
import LinkButtonContainer from '../../Components/Buttons/LinkButtonContainer';
import UploadVideos from '../../Assets/Icons/UploadVideos.svg';
import UploadImaging from '../../Assets/Icons/UploadImaging.svg';
import ViewImaging from '../../Assets/Icons/ViewPatientCts.svg';
import ViewVideos from '../../Assets/Icons/ViewVideos.svg';
import AnnotateVideos from '../../Assets/Icons/AnnotateVideos.svg';
import AnnotateImaging from '../../Assets/Icons/AnnotateImaging.svg';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';
import { Context } from '../../Utils/UserContext';

const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 1rem;
  border: 0.125rem solid ${colors.teal};
  padding: 3rem 0;
  height: max-content;
  width: 90%;
  min-width: 16rem;
  position: absolute;
  left: 5%;
  right: 5%;
  bottom: 5%;
  @media (max-width: 768px) {
    max-width: 25rem;
    position: static;
    margin: 0 auto;
  }
  @media (max-height: 650px) {
    position: static;
    margin: 0 auto;
  }
`;

const NoPermissionsText = styled.div`
  color: ${colors.teal};
  font-size: 1.5rem;
  text-align: center;
`;

const NoPermissionsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Links = () => {
  const [showModal, setShowModal] = useState(false);
  const mode = getFileTypeFromUrl();
  const { user } = useContext(Context);
  const history = useHistory();

  return (
    <>
      {showModal && <UploadModal setShowModal={setShowModal} mode={mode} />}
      <Container>
        {!user?.roles?.isUploader &&
          !user?.roles?.isViewer &&
          !user?.roles?.isAnnotator &&
          !user?.roles?.isSupplierAdmin &&
          !user?.roles?.isSuperUser &&
          !user?.roles?.isDoctor &&
          !user?.roles?.isDiagnostic &&
          !user?.roles?.isSuperPatient && (
            <NoPermissionsDiv>
              <NoData />
              <NoPermissionsText>
                You don't have any permissions yet.
              </NoPermissionsText>
              <NoPermissionsText>
                Please contact your administrator.
              </NoPermissionsText>
            </NoPermissionsDiv>
          )}
        <LinkButtonContainer>
          {(user?.roles?.isUploader ||
            user?.roles?.isSupplierAdmin ||
            user?.roles?.isSuperUser ||
            user?.roles?.isDoctor ||
            user?.roles?.isDiagnostic ||
            user?.roles?.isSuperPatient) && (
            <LinkButton
              onClick={setShowModal}
              imgsrc={mode === 'video' ? UploadVideos : UploadImaging}
              buttontext={mode === 'video' ? 'Upload Videos' : 'Upload Imaging'}
            />
          )}
          {(user?.roles?.isViewer ||
            user?.roles?.isUploader ||
            user?.roles?.isSupplierAdmin ||
            user?.roles?.isSuperUser ||
            user?.roles?.isDoctor ||
            user?.roles?.isDiagnostic ||
            user?.roles?.isSuperPatient) && (
            <LinkButton
              onClick={() =>
                history.push(mode === 'video' ? '/video/view' : '/imaging/view')
              }
              imgsrc={mode === 'video' ? ViewVideos : ViewImaging}
              buttontext={mode === 'video' ? 'View Videos' : 'View Imaging'}
            />
          )}
          {(user?.roles?.isAnnotator ||
            user?.roles?.isSupplierAdmin ||
            user?.roles?.isSuperUser) && (
            <LinkButton
              onClick={() =>
                history.push(
                  mode === 'video' ? '/video/annotate' : '/imaging/annotate'
                )
              }
              imgsrc={mode === 'video' ? AnnotateVideos : AnnotateImaging}
              buttontext={
                mode === 'video' ? 'Annotate Videos' : `Annotate Imaging`
              }
            />
          )}
        </LinkButtonContainer>
      </Container>
    </>
  );
};

export default Links;
