import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import LinkList from './LinkList';
import LinkButton from './Buttons/LinkButton';
import Home from '../Assets/Icons/Home.svg';
import UploadImaging from '../Assets/Icons/UploadImaging.svg';
import UploadVideos from '../Assets/Icons/UploadVideos.svg';
import ViewVideos from '../Assets/Icons/ViewVideos.svg';
import ViewImaging from '../Assets/Icons/ViewPatientCts.svg';
import AnnotateVideos from '../Assets/Icons/AnnotateVideos.svg';
import AnnotateImaging from '../Assets/Icons/AnnotateImaging.svg';
import { Context } from '../Utils/UserContext';
import styled from 'styled-components';
import { colors } from '../GlobalColors';
import RestoreImaging from '../Assets/Icons/Undo.svg';

const Container = styled.div`
  border-top: 0.063rem solid ${colors.medGray};
`;

const QuickLinks = ({ links }) => {
  const { user } = useContext(Context);
  const history = useHistory();

  const nav = {
    home: { icon: Home, title: 'Home', to: '/', authorized: true },
    uploadVideos: {
      icon: UploadVideos,
      title: 'Upload Videos',
      to: '/video/upload',
      authorized:
        user?.roles?.isUploader ||
        user?.roles?.isSupplierAdmin ||
        user?.roles?.isSuperUser ||
        user?.roles?.isDoctor ||
        user?.roles?.isDiagnostic ||
        user?.roles?.isSuperPatient,
    },
    uploadImaging: {
      icon: UploadImaging,
      title: 'Upload Imaging',
      to: '/imaging/upload',
      authorized:
        user?.roles?.isUploader ||
        user?.roles?.isSupplierAdmin ||
        user?.roles?.isSuperUser ||
        user?.roles?.isDoctor ||
        user?.roles?.isDiagnostic ||
        user?.roles?.isSuperPatient,
    },
    viewVideos: {
      icon: ViewVideos,
      title: 'View Videos',
      to: '/video/view',
      authorized:
        user?.roles?.isUploader ||
        user?.roles?.isViewer ||
        user?.roles?.isSupplierAdmin ||
        user?.roles?.isSuperUser ||
        user?.roles?.isDoctor ||
        user?.roles?.isDiagnostic ||
        user?.roles?.isSuperPatient,
    },
    viewImaging: {
      icon: ViewImaging,
      title: 'View Imaging',
      to: '/imaging/view',
      authorized:
        user?.roles?.isUploader ||
        user?.roles?.isViewer ||
        user?.roles?.isSupplierAdmin ||
        user?.roles?.isSuperUser ||
        user?.roles?.isDoctor ||
        user?.roles?.isDiagnostic ||
        user?.roles?.isSuperPatient,
    },
    annotateVideos: {
      icon: AnnotateVideos,
      title: 'Annotate Videos',
      to: '/video/annotate',
      authorized:
        user?.roles?.isAnnotator ||
        user?.roles?.isSupplierAdmin ||
        user?.roles?.isSuperUser,
    },
    annotateImaging: {
      icon: AnnotateImaging,
      title: 'Annotate Imaging',
      to: '/medical-imaging-annotator',
      authorized:
        user?.roles?.isAnnotator ||
        user?.roles?.isSupplierAdmin ||
        user?.roles?.isSuperUser,
    },
    restoreImaging: {
      icon: RestoreImaging,
      title: 'Restore Imaging',
      to: '/imaging/restore',
      authorized:
        user?.roles?.isUploader ||
        user?.roles?.isViewer ||
        user?.roles?.isSupplierAdmin ||
        user?.roles?.isSuperUser ||
        user?.roles?.isDoctor ||
        user?.roles?.isDiagnostic ||
        user?.roles?.isSuperPatient,
    },
  };

  return (
    <Container>
      <LinkList title='Quick Links'>
        {links.map((link) => {
          const navLink = nav[link];
          return navLink.authorized ? (
            <LinkButton
              key={navLink.title}
              small={true}
              onClick={() => history.push(navLink.to)}
              imgsrc={navLink.icon}
              buttontext={navLink.title}
            />
          ) : null;
        })}
      </LinkList>
    </Container>
  );
};

export default QuickLinks;
