import React from 'react';
// import BackgroundImg from '../../Assets/Images/Background.svg';
import BackgroundImg from '../../Assets/Images/RediMinds_Website.svg';
import LogoWhite from '../../Assets/Images/LogoWhite.svg';
import UsersDropdown from '../Dropdown';
import InlineLogo from './InlineLogo';
import StackedLogo from './StackedLogo';
import styled from 'styled-components';

const Background = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  background-image: url(${BackgroundImg});
  padding: 2rem;
`;

const RediMindsLogo = styled.img`
  float: left;
  @media (max-width: 768px) {
    width: 10rem;
  }
`;

const MainLayout = ({ children, withoutLogo, hideDropdown }) => {
  return (
    <Background>
      <RediMindsLogo src={LogoWhite} alt='Ground Truth Factory logo' />
      {!hideDropdown && <UsersDropdown />}
      {withoutLogo ? <StackedLogo /> : <InlineLogo />}
      {children}
    </Background>
  );
};

export default MainLayout;
