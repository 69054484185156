import React, { useEffect, useState, useContext, useCallback } from 'react';
import {
  Button,
  Popconfirm,
  Typography,
  Tooltip,
  Progress,
  notification,
  Modal,
  Input,
} from 'antd';
import {
  CloseSquareOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  RocketTwoTone,
  MailOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Preview from './Preview';
import Dashboard from '../../Components/Dashboard';
import Spinner from '../../Components/Spinner';
import LayoutColumn from '../../Components/LayoutColumn';
import ListContainer from '../../Components/FileList/ListContainer';
import ListItemany from '../../Components/FileList/itemany';
import Heading from '../../Components/Heading';
import QuickLinks from '../../Components/QuickLinks';
import VideoPlayer from '../../Components/VideoPlayer';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';
import {
  getDataSuppliers,
  getSurgicalEventFiles,
  getEventFileById,
  get3dModelFileById,
  getSurgicalEvents,
  deleteEventFile,
  startSegmentationByFileId,
  getViewSlicerFileById,
  generateSecretEmailFor3dModal,
  getUserById,
} from '../../Utils/axios';
import { getFileTypeFromUrl } from '../../Utils/getMode';
import ToolBar from '../../Components//ToolBar';
import { Context } from '../../Utils/UserContext';
import { processSegmentation } from '../../Utils/segmentationApi';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const NoUploadsDiv = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  color: ${colors.navy};
  font-weight: bold;
  margin: 5rem 0;
`;

const ViewLayout = ({ pageTitle, listIcon, quickLinksArray }) => {
  const { user } = useContext(Context);
  const [files, setFiles] = useState(null);
  const [recentFiles, setRecentFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [signedUrl, setSignedUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [pageItems, setPageItems] = useState(null);
  const [filter, setFilter] = useState(null);
  const [dataSuppliers, setDataSuppliers] = useState();
  const [surgicalEvents, setSurgicalEvents] = useState();
  const [search, setSearch] = useState('');
  const [showFailed, setShowFailed] = useState(false);
  const [inProgress, setInProgress] = useState({});
  const [isEmailOtpModalOpen, setIsEmailOtpModalOpen] = useState(false);
  const [patientEmail, setPatientEmail] = useState('');
  const [patientPhone, setPatientPhone] = useState('');
  const [requestedFiles, setRequestedFiles] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');

  const mode = getFileTypeFromUrl();

  const getRecentFiles = (allFiles) => {
    if (allFiles && allFiles.length) {
      let all = allFiles;
      all
        .sort((a, b) => {
          return (
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
          );
        })
        .reverse();
      if (all && all.length > 3) {
        setRecentFiles(all.slice(0, 3));
      } else {
        setRecentFiles(all);
      }
    } else {
      setRecentFiles([]);
    }
  };

  const itemsPerPage = 6;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginationData = {
    firstIndex: indexOfFirstItem,
    lastIndex: indexOfLastItem,
    numberItems: files?.length ?? 0,
    itemsPerPage: itemsPerPage,
    currentPage: currentPage,
    setCurrentPage: setCurrentPage,
  };

  useEffect(() => {
    getSurgicalEvents().then((res) => {
      setSurgicalEvents(res?.data ?? []);
    });

    if (user?.roles?.isSuperUser) {
      getDataSuppliers().then((res) => {
        setDataSuppliers(res?.data ?? []);
      });
    } else {
      setDataSuppliers(user?.dataSupplier);
    }
  }, [user]);

  const getFiles = useCallback(() => {
    let eventId = null;
    let dataSupplierId = null;
    let status = showFailed ? 'incomplete' : 'complete';

    if (filter) {
      eventId =
        typeof filter === 'number' && !user?.roles?.isSuperUser ? filter : null;
      dataSupplierId =
        (typeof filter === 'number' || filter === 'mine') &&
        user?.roles?.isSuperUser
          ? filter
          : null;
    }

    getSurgicalEventFiles(
      search,
      status,
      eventId,
      dataSupplierId,
      'undeleted'
    ).then((res) => {
      if (res) {
        let myFiles;
        if (user?.roles?.isDoctor) {
          if (requestedFiles) {
            myFiles = res?.data.filter(
              (f) => f.doctorRefId === user?.id.toString()
            );
          } else {
            myFiles = res?.data.filter((f) => f.uploadedById === user.id);
          }
        } else if (user?.roles?.isSuperPatient) {
          myFiles = res?.data.filter((f) => f.uploadedById === user.id);
        } else {
          if (filter && filter === 'my uploads') {
            myFiles = res?.data.filter((f) => f.uploadedById === user.id);
          } else {
            myFiles = res?.data;
          }
        }

        setFiles(myFiles);
        getRecentFiles(myFiles);
      } else {
        setFiles([]);
        setPageItems([]);
      }
    });
  }, [filter, search, user, showFailed, requestedFiles]);

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  useEffect(() => {
    if (files && files.length) {
      const lastPage = Math.ceil(files.length / itemsPerPage);
      const items =
        currentPage === lastPage
          ? files.slice(indexOfFirstItem)
          : files.slice(indexOfFirstItem, indexOfLastItem);
      setPageItems(items);
    } else {
      setPageItems([]);
    }
  }, [files, currentPage, indexOfFirstItem, indexOfLastItem]);

  const handleClick = async (file) => {
    const signed = await getEventFileById(file.id).then((res) => {
      return res?.data?.signedUrl;
    });
    if (mode === 'video') {
      setSignedUrl(signed);
      setShowModal(true);
    } else {
      const fileName = await file.fileUrl.split('/').pop();
      const encodedUrl = encodeURIComponent(signed);
      window.location = `/medical-imaging-viewer?name=${fileName}&url=${encodedUrl}`;
    }
  };

  const view3dModel = async (file) => {
    const signed = await get3dModelFileById(file.id).then((res) => {
      return res?.data?.signedUrl;
    });
    if (mode === 'video') {
      setSignedUrl(signed);
      setShowModal(true);
    } else {
      console.log('opeing 3d viewerr');
      // const fileName = await file.fileUrl.split('/').pop();
      // const encodedUrl = await encodeURIComponent(signed);
      // window.location = `/3d-model-viewer?name=${fileName}&url=${encodedUrl}`;
      // window.location = `/3d-viewer/website/index.html#model=${signed}`;
      window.location = `/3d-viewer/website/#model=${signed}`;
    }
  };

  const viewInSlicer = async (fileId) => {
    const signed = await getViewSlicerFileById(fileId).then((res) => {
      return res?.data?.signedUrl;
    });
    const viewSlicerUrl = `slicer://viewer/?segmentation=${signed?.segUrl}&image=${signed?.ctUrl}`;
    window.location.href = viewSlicerUrl;
  };

  const getFileHeader = () => {
    let header;
    if ((!filter && user?.roles?.isSuperUser) || (filter && filter === 'all')) {
      header = `All Uploads`;
    } else if (
      (!filter && !user?.roles?.isSuperUser) ||
      (filter && filter === 'mine')
    ) {
      header = `All ${user?.dataSupplier?.name} Uploads`;
    } else if (filter && filter === 'my uploads') {
      header = 'My Uploads';
    } else if (
      filter &&
      typeof filter === 'number' &&
      !user?.roles?.isSuperUser
    ) {
      let event = surgicalEvents.find((e) => e.id === filter);
      header = `Surgical Event: ${event.display}`;
    } else if (
      filter &&
      typeof filter === 'number' &&
      user?.roles?.isSuperUser
    ) {
      let dataSupplier = dataSuppliers.find((s) => s.id === filter);
      header = `Data Supplier: ${dataSupplier.name}`;
    }
    return `${header}${showFailed ? ' (Incomplete)' : ''}`;
  };

  const deleteFile = (file) => {
    deleteEventFile(`${file.id}-restore`)
      .then(() => {
        getFiles();
      })
      .catch((err) => console.log('ERROR: ', err));
  };

  const buttonProps = {
    type: 'default',
    style: { color: colors.teal, borderColor: colors.teal },
  };

  const getSegmentStatusColor = (segmentStatus, fieldStatus) => {
    if (!segmentStatus && fieldStatus === 'segment') {
      return undefined;
    }
    if (segmentStatus === fieldStatus) {
      return undefined;
    }
    return colors.medGray;
  };

  const startSegmentationApi = async (file, mode) => {
    const fileId = file?.id;
    const fileName = file?.fileUrl.split('/').pop();
    if (
      fileName.endsWith('.nii') ||
      fileName.endsWith('.nii.gz') ||
      fileName.endsWith('.zip')
    ) {
      // Process the file
      if (user?.roles?.isDiagnostic || user?.roles?.isSuperPatient) {
        try {
          const doctor = await getUserById(user?.assignedDoctorId);
          const response = await startSegmentationByFileId(
            `${fileId}-${user?.assignedDoctorId}`
          );
          getFiles();
          setCurrentPage(1);
          notification.success({
            message: `We have notified Radiologist Dr. ${doctor?.firstName} ${doctor?.lastName}. You'll be notified with email when 3D modal of this file built.`,
          });
          await processSegmentation(response, mode);
        } catch (e) {
          console.log('ERROR: ', e);
        }
      } else {
        try {
          const response = await startSegmentationByFileId(fileId);
          setInProgress({ ...inProgress, [fileId]: true });
          notification.success({ message: 'Segmentation started!' });
          const res = await processSegmentation(response, mode);
          setTimeout(() => {
            setInProgress({ ...inProgress, [fileId]: false });
            if (!res) {
              notification.error({
                message: 'Segmentation server is not running!',
              });
            } else {
              if (res.status === 200) {
                notification.success({ message: res.data?.message });
              } else if (res.status === 201) {
                notification.error({ message: res.data?.message });
              } else {
                notification.error({
                  message: 'Internal Server Error',
                });
              }
            }
            getFiles();
            setCurrentPage(1);
          }, 5000);
        } catch (e) {
          console.log('ERROR: ', e);
        }
      }
    } else if (fileName.endsWith('.dcm')) {
      // Don't process the file
      notification.error({
        message:
          'Please upload a compressed folder (.zip file) of series of DICOM Files.',
        description:
          'A dicom file may give inaccurate result. You need to upload a series of dcm files.',
      });
    } else {
      // Don't process the file
      notification.error({
        message:
          'Sorry! At this moment, we can only process NIFTI(.nii or .nii.gz) or DICOM(.dcm) files!',
      });
    }
  };

  const onNotifyPatientEmail = async () => {
    if (patientEmail === '' && patientPhone != '') {
      notification.error({
        message: "Patient's email missing!",
      });
    } else if (patientEmail != '' && patientPhone === '') {
      notification.error({
        message: "Patient's phone missing!",
      });
    } else if (patientEmail === '' && patientPhone === '') {
      notification.error({
        message: "Patient's email and phone are missing!",
      });
    } else if (patientEmail != '' && patientPhone != '') {
      const cpatientPhone = `+${patientPhone}`;
      const emailRegex =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      let isValidEmail = emailRegex.test(patientEmail);
      const phoneUtil = PhoneNumberUtil.getInstance();
      try {
        const parsedNumber = phoneUtil.parse(cpatientPhone);
        const isValidPhone = phoneUtil.isValidNumber(parsedNumber);
        if (!isValidEmail && isValidPhone) {
          notification.error({
            message: "Patient's email is invalid!",
          });
        } else if (isValidEmail && !isValidPhone) {
          notification.error({
            message: "Patient's phone is invalid!",
          });
        } else if (!isValidEmail && !isValidPhone) {
          notification.error({
            message: "Patient's email and phone are invalid!",
          });
        } else if (isValidEmail && isValidPhone) {
          try {
            const response = await generateSecretEmailFor3dModal(
              selectedFileId,
              patientEmail,
              patientPhone
            );
            if (!response) {
              notification.error({
                message: 'Something went wrong',
              });
              setIsEmailOtpModalOpen(false);
            } else {
              if (response.status === 200) {
                setIsEmailOtpModalOpen(false);
                notification.success({ message: response.data?.message });
              } else if (response.status === 400) {
                setIsEmailOtpModalOpen(false);
                notification.error({ message: response.data?.message });
              } else {
                setIsEmailOtpModalOpen(false);
                notification.error({
                  message: 'Internal Server Error',
                });
              }
            }
          } catch (error) {
            setIsEmailOtpModalOpen(false);
            notification.error({
              message: 'Some error occured while sending Patient email',
            });
            console.log(error);
          }
        }
      } catch (error) {
        notification.error({
          message: "Patient's phone is invalid!",
        });
      }
    }
  };

  function viewSlicerComponent(file) {
    if (user?.roles?.isDiagnostic || user?.roles?.isSuperPatient) {
      return (
        <Button
          type='primary'
          disabled={true}
          style={{
            height: 50,
            fontSize: 10,
            borderRadius: 10,
          }}
        >
          Request
          <br /> -ed
        </Button>
      );
    } else {
      return (
        <Button
          type='primary'
          disabled={false}
          style={{
            height: 50,
            fontSize: 10,
            borderRadius: 10,
          }}
          onClick={() => viewInSlicer(file?.id)}
        >
          View In
          <br />
          Slicer
        </Button>
      );
    }
  }

  function inProgressComponent() {
    if (user?.roles?.isDiagnostic || user?.roles?.isSuperPatient) {
      return (
        <Button
          type='primary'
          disabled={true}
          style={{
            height: 50,
            fontSize: 10,
            borderRadius: 10,
          }}
        >
          Request
          <br /> -ed
        </Button>
      );
    } else {
      return (
        <Button
          type='primary'
          disabled={true}
          style={{
            height: 50,
            fontSize: 10,
            borderRadius: 10,
          }}
        >
          In
          <br /> Progress
        </Button>
      );
    }
  }

  return (
    <Dashboard
      pageTitle={pageTitle}
      prev={mode === 'video' ? '/video' : '/imaging'}
      tools={
        <ToolBar
          isSuperUser={user?.roles?.isSuperUser}
          userSupplier={user?.dataSupplier}
          canFilterByUploader={
            user?.roles?.isViewer || user?.roles?.isSupplierAdmin
          }
          showCheck={true}
          dataSuppliers={dataSuppliers}
          surgicalEvents={surgicalEvents}
          setShowFailed={setShowFailed}
          setFilter={(e) => {
            setFilter(e);
            setCurrentPage(1);
          }}
          onClearFilter={() => {
            setFilter(null);
            setCurrentPage(1);
          }}
          search={search}
          setSearch={setSearch}
          setPageOne={() => setCurrentPage(1)}
          showFilter={
            user?.roles?.isDoctor ||
            user?.roles?.isDiagnostic ||
            user?.roles?.isSuperPatient
          }
        />
      }
    >
      {!files ? (
        <Spinner />
      ) : (
        <LayoutColumn
          left={
            <>
              <ListContainer
                isDoctor={user?.roles?.isDoctor}
                title={getFileHeader()}
                paginationData={paginationData}
                requestedFiles={requestedFiles}
                setRequestedFiles={setRequestedFiles}
              >
                {pageItems && pageItems.length ? (
                  pageItems.map((file) => (
                    <div key={file.id}>
                      <ListItemany
                        onClickFile={
                          mode === 'video' ? () => handleClick(file) : null
                        }
                        mode={mode}
                        icon={
                          file.uploadStatus === 'complete' ? (
                            listIcon
                          ) : (
                            <CloseSquareOutlined
                              style={{
                                marginRight: '0.5rem',
                              }}
                            />
                          )
                        }
                        name={file.fileUrl.split('/').pop()}
                        centerContent={
                          <>
                            {mode === 'ct' && (
                              <Button
                                type='primary'
                                disabled={false}
                                style={{
                                  height: 50,
                                  fontSize: 10,
                                  borderRadius: 10,
                                }}
                                onClick={() => handleClick(file)}
                              >
                                View
                                <br /> Upload
                              </Button>
                            )}
                          </>
                        }
                        leftContent={
                          inProgress[file.id] ? (
                            <Button
                              type='primary'
                              disabled={true}
                              style={{
                                height: 50,
                                fontSize: 10,
                                borderRadius: 10,
                              }}
                            >
                              in
                              <br /> progress
                            </Button>
                          ) : (
                            <>
                              {mode === 'ct' &&
                                (!file.segmentStatus ||
                                  file.segmentStatus === 'pending') && (
                                  <Popconfirm
                                    title={`Select Mode For Segmentation`}
                                    icon={
                                      <RocketTwoTone
                                        style={{ fontSize: '17px' }}
                                      />
                                    }
                                    okText={
                                      <Tooltip title='Coarser (less accurate) / Shorter Time (upto 4 mins)'>
                                        Fast Mode
                                      </Tooltip>
                                    }
                                    cancelText={
                                      <Tooltip title='Refined (more accurate) / Longer Time (upto 70 mins)'>
                                        Slow Mode
                                      </Tooltip>
                                    }
                                    okButtonProps={buttonProps}
                                    cancelButtonProps={buttonProps}
                                    onConfirm={() =>
                                      startSegmentationApi(file, 'fast')
                                    }
                                    onCancel={() =>
                                      startSegmentationApi(file, 'slow')
                                    }
                                  >
                                    <Button
                                      type='primary'
                                      disabled={false}
                                      style={{
                                        height: 50,
                                        fontSize: 10,
                                        borderRadius: 10,
                                      }}
                                    >
                                      {user?.roles?.isDiagnostic ||
                                      user?.roles?.isSuperPatient
                                        ? 'Request'
                                        : 'Build 3D'}
                                      <br />
                                      Model
                                    </Button>
                                  </Popconfirm>
                                )}
                              {mode === 'ct' &&
                                file.segmentStatus === 'in progress' &&
                                inProgressComponent()}
                              {mode === 'ct' &&
                                file.segmentStatus === 'view slicer' &&
                                viewSlicerComponent(file)}
                              {mode === 'ct' &&
                                file.segmentStatus === 'complete' && (
                                  <Button
                                    type='primary'
                                    disabled={false}
                                    style={{
                                      height: 50,
                                      fontSize: 10,
                                      borderRadius: 10,
                                    }}
                                    onClick={() => view3dModel(file)}
                                  >
                                    View 3D
                                    <br />
                                    Model
                                  </Button>
                                )}
                            </>
                          )
                        }
                        rightContent={
                          <div style={{ display: 'flex', gap: '4px' }}>
                            <Popconfirm
                              title='Delete File?'
                              icon={
                                <QuestionCircleOutlined
                                  style={{ color: 'red' }}
                                />
                              }
                              okText='Confirm'
                              cancelText='Exit'
                              okButtonProps={buttonProps}
                              cancelButtonProps={buttonProps}
                              onConfirm={() => deleteFile(file)}
                            >
                              <Tooltip title='Delete'>
                                <Button
                                  type='primary'
                                  shape='circle'
                                  icon={<DeleteOutlined />}
                                  style={{
                                    background: 'red',
                                    position: 'absolute',
                                    top: 10,
                                    borderColor: colors.red,
                                  }}
                                />
                              </Tooltip>
                            </Popconfirm>
                            {user?.roles?.isDoctor ||
                            user?.roles?.isDiagnostic ? (
                              <>
                                <Tooltip title='Notify Patient'>
                                  <Button
                                    type='primary'
                                    shape='circle'
                                    key={file.id}
                                    icon={<MailOutlined />}
                                    disabled={
                                      mode === 'ct' &&
                                      file.segmentStatus != 'complete'
                                    }
                                    onClick={() => {
                                      setSelectedFileId(file?.id);
                                      setSelectedFileName(
                                        file?.fileUrl.split('/').pop()
                                      );
                                      setIsEmailOtpModalOpen(true);
                                    }}
                                    style={{
                                      position: 'relative',
                                      top: 10,
                                      left: 40,
                                      borderColor: colors.blue,
                                    }}
                                  />
                                </Tooltip>
                                <Modal
                                  title={
                                    <h3 style={{ fontWeight: 'bold' }}>
                                      Notify Patient to view 3d Model of this
                                      file
                                    </h3>
                                  }
                                  okText='Notify'
                                  key={file?.id}
                                  visible={isEmailOtpModalOpen}
                                  maskStyle={{ backgroundColor: '#0012291a' }}
                                  onOk={() => onNotifyPatientEmail()}
                                  onCancel={() => setIsEmailOtpModalOpen(false)}
                                >
                                  <div style={{ padding: '2px' }}>
                                    <h3>
                                      File name:{' '}
                                      <span style={{ color: 'darkGreen' }}>
                                        {selectedFileName}
                                      </span>
                                    </h3>
                                    <br />
                                    <h3>
                                      Patient's Email Address{' '}
                                      <span style={{ color: 'red' }}>*</span>
                                    </h3>
                                    <Input
                                      type='text'
                                      required={true}
                                      style={{ fontSize: '17px' }}
                                      value={patientEmail}
                                      onChange={(e) =>
                                        setPatientEmail(e.target.value)
                                      }
                                    />
                                    <br />
                                    <br />
                                    <h3>
                                      Patient's Phone Number{' '}
                                      <span style={{ color: 'red' }}>*</span>
                                    </h3>
                                    <PhoneInput
                                      name='phone'
                                      type='number'
                                      placeholder='Enter your Phone'
                                      country={'us'}
                                      preferredCountries={['us', 'in', 'ca']}
                                      //onlyCountries={['us']}
                                      //disableCountryCode={true}
                                      containerStyle={{
                                        width: '100%',
                                        height: '35px',
                                      }}
                                      inputStyle={{
                                        width: '100%',
                                        height: '35px',
                                      }}
                                      value={patientPhone}
                                      onChange={(e) => setPatientPhone(e)}
                                    />
                                    <br />
                                    <br />
                                    <span style={{ color: 'teal' }}>
                                      Note: Please fill in these details
                                      carefully; otherwise, the user may not be
                                      able to access this file.
                                    </span>
                                  </div>
                                </Modal>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        }
                        dateText={
                          file.uploadStatus === 'complete' ? (
                            <>
                              <div>
                                Uploaded {moment(file.updatedAt).format('LL')}{' '}
                                {/* by {file.fileUrl.slice(0, 3)} */}
                              </div>

                              {mode === 'ct' && (
                                <div>
                                  <span
                                    style={{
                                      color: getSegmentStatusColor(
                                        file?.segmentStatus,
                                        'segment'
                                      ),
                                    }}
                                  >
                                    Segment&nbsp;
                                  </span>
                                  &nbsp; &gt; &nbsp;
                                  <span
                                    style={{
                                      color: getSegmentStatusColor(
                                        file?.segmentStatus,
                                        'in progress'
                                      ),
                                    }}
                                  >
                                    In Progress
                                  </span>
                                  &nbsp; &gt;&nbsp;
                                  <span
                                    style={{
                                      color: getSegmentStatusColor(
                                        file?.segmentStatus,
                                        'complete'
                                      ),
                                    }}
                                  >
                                    Done
                                  </span>
                                </div>
                              )}
                            </>
                          ) : (
                            <div
                              style={{
                                color: colors.red,
                                fontSize: '0.65rem',
                              }}
                            >
                              Status: {file.uploadStatus}, Progress:&nbsp;
                              {(file.uploadProgress * 100).toFixed(0)}%
                            </div>
                          )
                        }
                      />
                    </div>
                  ))
                ) : (
                  <p style={{ textAlign: 'center' }}>Nothing to Display</p>
                )}
              </ListContainer>
            </>
          }
          right={
            <>
              <Heading>
                {requestedFiles ? 'Recently Requested' : 'Recently Uploaded'}
              </Heading>
              <Container>
                {recentFiles && recentFiles.length ? (
                  recentFiles.map((file) => (
                    <Preview
                      key={file.id}
                      file={file}
                      fileName={file.fileUrl.split('/').pop()}
                      onClick={() => handleClick(file)}
                    />
                  ))
                ) : (
                  <NoUploadsDiv>
                    {requestedFiles ? 'No Request Yet' : 'No Uploads Yet'}
                  </NoUploadsDiv>
                )}
              </Container>
              <QuickLinks links={quickLinksArray} />
            </>
          }
        />
      )}
      {showModal && <VideoPlayer src={signedUrl} setShowModal={setShowModal} />}
    </Dashboard>
  );
};

export default ViewLayout;
