import React, { useEffect, useCallback, useContext } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Context } from '../Utils/UserContext';
import MainLayout from '../Components/MainLayout';

// This component needs to be re worked so we can pass props to the component on the routes page if needed.
const PublicRoute = ({ component: Component, path, ...rest }) => {
  const { user } = useContext(Context);

  const authChecker = useCallback(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        if (user) {
          rest.history.push('/');
        }
      })
      .catch((e) => {
        console.log(e);
        if (path === '/view-3d-modal-patient') {
          rest.history.push(path + rest.location.search);
        } else {
          rest.history.push('/login');
        }
      });
  }, [user, rest.history]);

  useEffect(() => {
    if (authChecker) authChecker();
  }, [authChecker]);

  const render = (props) =>
    true ? (
      <MainLayout withoutLogo={true} hideDropdown={true}>
        <Component {...props} />
      </MainLayout>
    ) : null;

  return <Route path={path} render={render} {...rest} />;
};

export default withRouter(PublicRoute);
